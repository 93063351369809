// ReassignPlanModal.js
import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { BoxContext } from './BoxContext';

const API_URL = process.env.REACT_APP_API_URL;

const ReassignPlanModal = ({ show, onClose, shipmentId, handleReassignPlan }) => {
  const { plans } = useContext(BoxContext);
  const [boxContents, setBoxContents] = useState([]);
  const [selectedOutboundId, setSelectedOutboundId] = useState(null);
  const [selectedNewPlan, setSelectedNewPlan] = useState(null);
  const [outboundIdOptions, setOutboundIdOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);

  useEffect(() => {
    if (show) {
      fetchBoxContents();
      updatePlanOptions();
    }
  }, [show, plans]);

  useEffect(() => {
    if (boxContents.length > 0) {
      // Create a Set to track unique FBA_ID and OUTBOUND_ID combinations
      const uniqueCombinations = new Set();

      // Create options for the OUTBOUND_ID selector
      const outboundIdOptions = boxContents.reduce((acc, item) => {
        const comboKey = `${item.FBA_ID}-${item.OUTBOUND_ID}`;
        
        if (!uniqueCombinations.has(comboKey)) {
          uniqueCombinations.add(comboKey);
          acc.push({
            value: item.FBA_ID,
            label: `${item.FBA_ID} - ${item.OUTBOUND_ID}`
          });
        }

        return acc;
      }, []);

      setOutboundIdOptions(outboundIdOptions);
      console.log(outboundIdOptions);
    }
  }, [boxContents]);

  const fetchBoxContents = async () => {
    try {
      const response = await fetch(`${API_URL}/api/get_all_box_content_plans/${shipmentId}`);
      const data = await response.json();
      console.log(data);
      setBoxContents(data);
    } catch (error) {
      console.error('Error fetching box contents:', error);
    }
  };

  const updatePlanOptions = () => {
    const updatedPlanOptions = plans.map(plan => ({
      value: plan.fba_shipment_id,
      label: `${plan.fba_shipment_id} - ${plan.name} - ${plan.items.length} SKUs - ${plan.items.reduce((sum, item) => sum + item.quantity, 0)} Units`
    }));
    setPlanOptions(updatedPlanOptions);
  };

  const handleOutboundIdSelect = (selectedOption) => {
    setSelectedOutboundId(selectedOption);
  };

  const handlePlanSelect = (selectedOption) => {
    setSelectedNewPlan(selectedOption);
  };

  const handleConfirmReassign = () => {
    console.log('Selected Outbound ID:', selectedOutboundId?.value);
    console.log('Selected New Plan ID:', selectedNewPlan?.value);

    handleReassignPlan(selectedOutboundId?.value, selectedNewPlan?.value);
    onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-4/5 h-4/5 overflow-auto">
        <h2 className="text-xl mb-4">Reassign Plan</h2>
        
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Select Plan We Already Did Contents For:</label>
          <Select
            value={selectedOutboundId}
            onChange={handleOutboundIdSelect}
            options={outboundIdOptions}
            className="mb-4"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Select New Plan We Want to Transfer Contents Over To:</label>
          <Select
            value={selectedNewPlan}
            onChange={handlePlanSelect}
            options={planOptions}
            className="mb-4"
          />
        </div>

        <button
          className="bg-blue-500 text-white p-2 rounded mr-2"
          onClick={handleConfirmReassign}
          disabled={!selectedOutboundId || !selectedNewPlan}
        >
          Confirm
        </button>
        <button
          className="bg-gray-500 text-white p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ReassignPlanModal;
