import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper, TextField, Typography, Container } from '@mui/material';
import { USAContext } from './USAContext';
const API_URL = process.env.REACT_APP_API_URL;

const ValidateShipmentInfo = ({ setIsStepValidated }) => {
    const { shipmentInfo, selectedPlan, handleNext } = useContext(USAContext);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedPlan) {
            setAddress(selectedPlan.destination_address.address_line_1 || '');
            setCity(selectedPlan.destination_address.city || '');
            setState(selectedPlan.destination_address.state_or_province_code || '');
            setZip(selectedPlan.destination_address.postal_code || '');
            
            // Fetch the labels for the selected plan
            const fetchLabels = async () => {
                try {
                    const response = await fetch(`https://app.yyzprep.ca/api/fba-transport/v0/plans/${selectedPlan.id}/labels?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
                    const data = await response.json();
                    
                    // Filter labels to find the 4" x 6" or fallback to 8.5" x 11"
                    const label = data.data.find(label => label.name.includes('4" x 6"')) || 
                                  data.data.find(label => label.name.includes('8.5" x 11"'));

                    if (label) {
                        setPdfUrl(label.url);
                    } else {
                        setPdfUrl('No UPS Label Found');
                    }
                } catch (error) {
                    console.error('Error fetching labels:', error);
                    setPdfUrl('No UPS Label Found');
                }
            };

            fetchLabels();
        }
    }, [selectedPlan]);

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleZipChange = (event) => {
        setZip(event.target.value);
    };

    const handleValidate = () => {
        if (address && city && state && zip) {
            console.log('Validated');
            setIsStepValidated(true);
        }
    };

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">Marketplace Data</Typography>
                    <Paper style={{ padding: '16px', marginBottom: '8px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Address"
                                    fullWidth
                                    value={address}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="City"
                                    fullWidth
                                    value={city}
                                    onChange={handleCityChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="State"
                                    fullWidth
                                    value={state}
                                    onChange={handleStateChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Zip"
                                    fullWidth
                                    value={zip}
                                    onChange={handleZipChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">UPS Labels</Typography>
                    {pdfUrl !== 'No UPS Label Found' ? (
                        <embed
                            src={pdfUrl}
                            width="100%"
                            height="500px"
                            type="application/pdf"
                            style={{ border: 'none' }}
                        />
                    ) : (
                        <Typography>No UPS Label Found</Typography>
                    )}
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" marginTop={3}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/usa/${selectedPlan.fba_shipment_id}/fda`)}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidate}
                    disabled={!address || !city || !state || !zip}
                >
                    Validate
                </Button>
            </Box>
        </Container>
    );
};

export default ValidateShipmentInfo;
