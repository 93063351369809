// BillingContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create the BillingContext
export const BillingContext = createContext();

// Provider Component
export const BillingProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState(() => {
    const storedClient = localStorage.getItem('selectedClient');
    return storedClient ? JSON.parse(storedClient) : null;
  });

  const [startDate, setStartDate] = useState(() => {
    const storedStartDate = localStorage.getItem('startDate');
    return storedStartDate ? new Date(storedStartDate) : null;
  });

  const [endDate, setEndDate] = useState(() => {
    const storedEndDate = localStorage.getItem('endDate');
    return storedEndDate ? new Date(storedEndDate) : null;
  });

  const [clients, setClients] = useState(() => {
    const storedClients = localStorage.getItem('clients');
    return storedClients ? JSON.parse(storedClients) : [];
  });

  const [clientData, setClientData] = useState(() => {
    const storedClientData = localStorage.getItem('clientData');
    return storedClientData ? JSON.parse(storedClientData) : null;
  });

  const [clientShipments, setClientShipments] = useState(() => {
    const storedShipments = localStorage.getItem('clientShipments');
    return storedShipments ? JSON.parse(storedShipments) : [];
  });

  const [shipmentItems, setShipmentItems] = useState(() => {
    const storedItems = localStorage.getItem('shipmentItems');
    return storedItems ? JSON.parse(storedItems) : {};
  });

  // Save data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedClient', JSON.stringify(selectedClient));
    localStorage.setItem('startDate', startDate ? startDate.toISOString() : '');
    localStorage.setItem('endDate', endDate ? endDate.toISOString() : '');
    localStorage.setItem('clients', JSON.stringify(clients));
    localStorage.setItem('clientData', JSON.stringify(clientData));
    localStorage.setItem('clientShipments', JSON.stringify(clientShipments));
    localStorage.setItem('shipmentItems', JSON.stringify(shipmentItems));
  }, [selectedClient, startDate, endDate, clients, clientData, clientShipments, shipmentItems]);

  return (
    <BillingContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        clients,
        setClients,
        clientData,
        setClientData,
        clientShipments,
        setClientShipments,
        shipmentItems,
        setShipmentItems,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};
