import React, { useState } from 'react';
import './presetStyle.css'; // Ensure this path is correct

const AddNewBoxModal = ({ isOpen, onClose, onAdd }) => {
  const [newBox, setNewBox] = useState({
    NAME: '',
    LENGTH: '',
    WIDTH: '',
    HEIGHT: ''
  });

  const handleChange = (e) => {
    setNewBox({ ...newBox, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(newBox);
    setNewBox({ NAME: '', LENGTH: '', WIDTH: '', HEIGHT: '' });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Add New Box</h2>
          <span className="modal-close" onClick={onClose}>&times;</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-form-group">
            <label htmlFor="newBoxName">Name:</label>
            <input
              id="newBoxName"
              name="NAME"
              value={newBox.NAME}
              onChange={handleChange}
              className="form-control mb-2"
              required
            />
          </div>
          <div className="modal-form-group">
            <label htmlFor="newBoxLength">Length:</label>
            <input
              id="newBoxLength"
              name="LENGTH"
              type="number"
              value={newBox.LENGTH}
              onChange={handleChange}
              className="form-control mb-2"
              required
            />
          </div>
          <div className="modal-form-group">
            <label htmlFor="newBoxWidth">Width:</label>
            <input
              id="newBoxWidth"
              name="WIDTH"
              type="number"
              value={newBox.WIDTH}
              onChange={handleChange}
              className="form-control mb-2"
              required
            />
          </div>
          <div className="modal-form-group">
            <label htmlFor="newBoxHeight">Height:</label>
            <input
              id="newBoxHeight"
              name="HEIGHT"
              type="number"
              value={newBox.HEIGHT}
              onChange={handleChange}
              className="form-control mb-2"
              required
            />
          </div>
          <div className="modal-buttons">
          <button type="button" onClick={onClose} className="btn btn-secondary bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded">Cancel</button>
          <button type="submit" className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">Add Box</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewBoxModal;
