import React, { useState, useContext } from 'react';
import { BoxContext } from './BoxContext';
import moment from 'moment';

const SendEmail = ({ recipientEmail, onClose }) => {
  const { selectedNickname, shipmentInfo } = useContext(BoxContext);
console.log(shipmentInfo);
  const [message, setMessage] = useState(`Staff Comment:

Time of Comment: ${moment().format('ddd, MMM Do [at] h:mm A')}
Employee Name: ${selectedNickname}
Outbound Name: ${shipmentInfo.name}
Outbound Link: https://app.yyzprep.ca/shipments/outbound/${shipmentInfo.id}

Please provide any information requested, or process any adjustments or changes as needed ASAP and let us know.

Send us an email, text, or call to notify us of any changes that may be necessary. Due to the volume of shipments being processed, and depending on your shipment type, it may be adjusted to ship out to improve lead times.

Kind Regards,
YYZ Prep Team`);

  const sendEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientEmail: recipientEmail,
          subject: `[ACTION REQUIRED] YYZ Prep | Outbound Shipment Issue`,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Email sent successfully');
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Send Email to {recipientEmail}</h2>
        
        <label className="block mb-4 text-sm font-medium text-gray-700">
          Message
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            rows="10"
          />
        </label>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={sendEmail}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
