import React from 'react';

const NicknameModal = ({ nicknames, onSelect }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl mb-4">Select Your Nickname</h2>
        <div className="grid grid-cols-1 gap-2">
          {nicknames.map((nicknameObj, index) => (
            <button
              key={index}
              onClick={() => onSelect(nicknameObj.NICKNAME)}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              {nicknameObj.NICKNAME}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NicknameModal;
