import React, { useState, useEffect, useContext } from 'react';
import BoxDimensions from './BoxDimensions';
import { useParams } from 'react-router-dom';
import AddItem from './AddItem';
import BoxItemList from './BoxItemList';
import AddNewBoxModal from './AddNewBoxModal';
import { BoxContext } from './BoxContext';
const API_URL = process.env.REACT_APP_API_URL;

const BoxDetails = ({ boxNumber, isOpen }) => {
  const [saving, setSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { boxes, refreshBoxes, shipmentItems, asinData, reloadShipmentData, clientInfo, fetchStandardBoxes, standardBoxes, selectedPlan, setBoxes, selectedNickname } = useContext(BoxContext);
  const box = boxes.find(x => x.boxNumber == boxNumber)
  
  const { shipmentId } = useParams(); // Use useParams to get the shipmentId from the URL

  const [details, setDetails] = useState({
    LENGTH: '',
    WIDTH: '',
    HEIGHT: '',
    WEIGHT: ''
  });

  useEffect(() => {

      setDetails({
        LENGTH: box.length || '',
        WIDTH: box.width  || '',
        HEIGHT: box.height  || '',
        WEIGHT: box.weight  || ''
      });
      // setDetails({
      //   LENGTH: box.items[0].LENGTH || '',
      //   WIDTH: box.items[0].WIDTH || '',
      //   HEIGHT: box.items[0].HEIGHT || '',
      //   WEIGHT: box.items[0].WEIGHT || ''
      // });
    
  }, [box]);


  if (!box) {
    return <div>Box not found</div>;
  }
  const updateBoxDetailsInDb = async (shipmentId, selectedPlan, boxNumber, length, width, height, weight) => {
    if (!boxNumber){
      return;
    }
    console.log('Updating box details in DB with box number:', boxNumber); // Add this line
    console.log(length, width, height, weight); // Add this line  
    console.log("Calling updateBoxDetailsInDb with:", shipmentId, selectedPlan, boxNumber, length, width, height, weight); // Add this line
    setSaving(true);
    console.log('Saving:', saving); // Add this line
    try {
      const response = await fetch(`${API_URL}/api/update_box_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shipmentId, selectedPlan, boxNumber, length, width, height, weight, selectedNickname }),
      });

      const result = await response.json();
      console.log(result); // Add this line
      if (result.success) {
        console.log("Success!");
        console.log("LENGTH IS:", length);  
        
        console.log('Box details updated successfully, refresh');
        await refreshBoxes();
        console.log('Refreshed boxes');
      } else {
        console.error('Error updating box details:', result.error);
      }
    } catch (error) {
      console.error('Error updating box details:', error);
    } finally {
      setSaving(false);
    }
  };

  // const handleDimensionBlur = async (field, value) => {
  //   console.log('handleDimensionBlur called with:', field, value); // Add this line

  //   if (field === 'ALL') {
  //     console.log("Field is ALL");  
  //     console.log(box);
  //     console.log("Values Received:", value.LENGTH, value.WIDTH, value.HEIGHT, value.WEIGHT); 
  //     await updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, value.LENGTH, value.WIDTH, value.HEIGHT, value.WEIGHT);
  //   } else {
  //     console.log("Field is NOT ALL");  
  //     const updatedDetails = { ...details, [field]: value };
  //     setDetails(updatedDetails);
  //     console.log("Updated details:", updatedDetails);  
  //     await updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, value.LENGTH, value.WIDTH, value.HEIGHT, value.WEIGHT);
  //   }
  // };
  const handleDimensionBlur = async (length, width, height, weight) => {
    console.log('handleDimensionBlur called with:', { length, width, height, weight });
  
    // Create updated details object
    const updatedDetails = {
      LENGTH: length || details.LENGTH,
      WIDTH: width || details.WIDTH,
      HEIGHT: height || details.HEIGHT,
      WEIGHT: weight || details.WEIGHT,
    };
  
    setDetails(updatedDetails); // Update state with new details
  
    console.log('Updating all fields with:', updatedDetails);
  
    // Update all fields in the database
    await updateBoxDetailsInDb(
      shipmentId,
      selectedPlan,
      box.boxNumber,
      updatedDetails.LENGTH,
      updatedDetails.WIDTH,
      updatedDetails.HEIGHT,
      updatedDetails.WEIGHT
    );

      // Ensure box remains open
  };
  
  
  const handleAddNewBox = async (newBox) => {
    setSaving(true);
    try {
      const response = await fetch(`${API_URL}/api/add_standard_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBox),
      });

      const result = await response.json();
      if (result.success) {
        fetchStandardBoxes();
        setIsModalOpen(false);
      } else {
        console.error('Error adding preset box:', result.error);
      }
    } catch (error) {
      console.error('Error adding preset box:', error);
    } finally {
      setSaving(false);
    }
  };

  const updateQuantityInDb = async (shipmentId, boxNumber, sku, quantity) => {
    setSaving(true);
    try {
      const response = await fetch(`${API_URL}/api/update_item_quantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shipmentId, selectedPlan, boxNumber, sku, quantity, selectedNickname }),
      });

      const result = await response.json();
      if (result.success) {
        await refreshBoxes();
      } else {
        console.error('Error updating quantity:', result.error);
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleItemChange = (e, field, index) => {
    const newItems = [...box.items];
    newItems[index][field] = e.target.value;
    const updatedBox = { ...box, items: newItems };
    updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, box.length, box.width, box.height, box.weight);
  };

  const handleItemBlur = (index) => {
    const item = box.items[index];
    updateQuantityInDb(shipmentId, box.boxNumber, item.SKU, item.QTY);
  };

  // const handleDateChange = (date, index) => {
  //   const newItems = [...box.items];
  //   newItems[index].expiry = date;
  //   const updatedBox = { ...box, items: newItems };
  //   updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, box.length, box.width, box.height, box.weight);
  // }

  const formatDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setUTCHours(0, 0, 0, 0); // Set time to midnight UTC
    return adjustedDate.toISOString().split('T')[0]; // Return only the date part
};



  const handleDateChange = async (date, index) => {
    const newItems = [...box.items];
    newItems[index].expiry = formatDate(date);
    const updatedBox = { ...box, items: newItems };


    // Find the corresponding item from shipmentItems based on SKU
    const itemSku = newItems[index].SKU; // Assuming SKU is the identifier in box.items
    console.log(itemSku);
    const item = shipmentItems.find(shipmentItem => shipmentItem.item.merchant_sku === itemSku);

    const newExpiryDate = date;
    const itemId = item.id;

    const currentDate = new Date();
    const differenceInDays = (date - currentDate) / (1000 * 60 * 60 * 24);

    try {
        console.log("ITEM ID WE'RE SENDING PATCH TO: ", itemId);
        const modifiedItem = {
            ...item,
            expiry_date: newExpiryDate
        };
        const response = await fetch(`${API_URL}/api/update-expiry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                itemId: itemId,
                shipmentId: shipmentId,
                item: modifiedItem,
            }),
        });

        if (response.ok) {
            console.log('Expiry date updated successfully');
            reloadShipmentData();           
        } else {
            console.error('Failed to update expiry date');
            alert('Failed to update expiry date');
        }
    } catch (error) {
        console.error('Error updating expiry date:', error);
        alert('Error updating expiry date');
    }

};

  const handleRemoveItem = async (index) => {
    const item = box.items[index];
    console.log(box);
    try {
      console.log("Item:", item);
      await updateQuantityInDb(shipmentId, box.boxNumber, item.SKU, 0);
      const newItems = [...box.items];
      newItems.splice(index, 1);
      const updatedBox = { ...box, items: newItems };
  
      // Check if the box is empty
      if (newItems.length === 0) {
        // Handle empty box scenario, e.g., setting dimensions to default values
        await updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, box.length, box.width, box.height, box.weight);
      } else {
        await updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, box.length, box.width, box.height, box.weight);
      }
  
      // Update the state to reflect the removed item
      const updatedBoxes = boxes.map(b => 
        b.boxNumber === box.boxNumber ? updatedBox : b
      );
  
      if (newItems.length === 0) {
        // Ensure empty box is not removed from state
        setBoxes(updatedBoxes);
      } else {
        setBoxes(updatedBoxes);
      }
  
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };
  
  
  
  return (
    <div className="flex flex-col">
   {saving && (
  <div className="fixed bottom-4 right-4 bg-yellow-500 text-white p-8 text-2xl rounded-lg shadow-lg z-50">
    Saving...
  </div>
)}



      <AddNewBoxModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onAdd={handleAddNewBox} 
      />

      <BoxDimensions 
        details={details} 
        boxNumber={boxNumber}
        shipmentItems={shipmentItems}
        handleDimensionBlur={handleDimensionBlur} 
        setDetails={setDetails} 
        shipmentId  = {shipmentId}
        selectedBox={box}
        selectedNickname={selectedNickname}
        asinData={asinData}
        saving={saving}
        standardBoxes={standardBoxes}
        onUpdateBoxDetails={updateBoxDetailsInDb}
      />

      {isOpen && (
        <hr className="my-4 border-t border-gray-300" />
      )}

      <AddItem 
        selectedNickname={selectedNickname}
        selectedPlan={selectedPlan}
        shipmentItems={shipmentItems} 
        onAddItem={(newItem) => {
          console.log("Add Item component, box received:", box);
          const existingItemIndex = box.items.findIndex(item => item.SKU === newItem.sku);

          if (existingItemIndex !== -1) {
            const newItems = [...box.items];
            newItems[existingItemIndex].QTY = Number(newItems[existingItemIndex].QTY) + Number(newItem.qty);
            const updatedBox = { ...box, items: newItems };
            updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, updatedBox.items[0].LENGTH, updatedBox.items[0].WIDTH, updatedBox.items[0].HEIGHT, updatedBox.items[0].WEIGHT);
          } else {
            const newItemForBox = {
              SKU: newItem.sku,
              ASIN: newItem.asin,
              FNSKU: newItem.fnsku,
              QTY: newItem.qty,
              LENGTH: newItem.length,
              WIDTH: newItem.width,
              HEIGHT: newItem.height,
              WEIGHT: newItem.weight,
              TITLE: newItem.title,
              PALLET: newItem.pallet,
              IMAGES: newItem.images, // Assuming IMAGES is the property used by BoxItemList to display the thumbnail
              // Add any other properties that BoxItemList relies on
            };
            const newItems = [...box.items, newItemForBox];
            const updatedBox = { ...box, items: newItems };
            console.log("Sending updated box to updateBoxDetailsInDb:", updatedBox);
            updateBoxDetailsInDb(shipmentId, selectedPlan, box.boxNumber, updatedBox.items[0].LENGTH, updatedBox.items[0].WIDTH, updatedBox.items[0].HEIGHT, updatedBox.items[0].WEIGHT);
          }

          refreshBoxes();
          console.log('Item added successfully');
        }} 
        boxNumber={box.boxNumber}
        length={details.LENGTH}
        width={details.WIDTH}
        height={details.HEIGHT}
        weight={details.WEIGHT}
        shipmentId={shipmentId}
      />

      <BoxItemList 
        boxNumber={boxNumber} 
        selectedNickname={selectedNickname}
        shipmentItems={shipmentItems} 
        asinData={asinData} 
        country={clientInfo.country}
        handleItemChange={handleItemChange} 
        handleItemBlur={handleItemBlur} 
        handleDateChange={handleDateChange} 
        onRemoveItem={handleRemoveItem}
      />
    </div>
  );
};

export default BoxDetails;
