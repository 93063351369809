import React, { useState, useEffect } from 'react';
import { TextField, Typography, Card, CardContent, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';

const API_URL = process.env.REACT_APP_API_URL;

// Styled component for the hover effect
const StyledCard = styled(Card)({
  cursor: 'pointer',
  display: 'flex',  // Make the card content flex
  alignItems: 'center',  // Center the content vertically
  '&:hover': {
    backgroundColor: '#f5f5f5',
    transition: 'transform 0.2s ease-in-out',  // Smooth transition
  },
  marginBottom: '16px',
});

// Styled component for the thumbnail image
const Thumbnail = styled('img')({
  width: '100px',  // Set the size of the thumbnail
  height: '100px',
  objectFit: 'cover',  // Maintain aspect ratio
  marginRight: '16px',  // Add space between the image and the content
  borderRadius: '8px',  // Add rounded corners
  padding: '8px',  // Add padding around the image
  backgroundColor: '#fff',  // Optional: background color to emphasize padding
});

// Function to highlight matching text
const highlightMatch = (text, searchTerms) => {
  return searchTerms.reduce((acc, term) => {
    const regex = new RegExp(`(${term})`, 'gi');
    return acc.replace(regex, '<span style="background-color: yellow;">$1</span>');
  }, text);
};

function InboundSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [shelfAisle, setShelfAisle] = useState('');
  const [shelfHeight, setShelfHeight] = useState('');
  const [shelfDepth, setShelfDepth] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/inbound-data`);
        const result = await response.json();
        setData(result);
        console.log(result);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const searchTerms = searchTerm.toLowerCase().split(' ').filter(term => term.length > 0);
      const results = Object.entries(data)
        .flatMap(([inboundId, items]) => {
          if (!Array.isArray(items)) {
            return [];
          }
          return items.map((item) => {
            const itemMatch = searchTerms.some(term =>
              item.product_title.toLowerCase().includes(term)
            );
            const inboundMatch = searchTerms.some(term =>
              inboundId.toLowerCase().includes(term) ||
              item.client_name?.toLowerCase().includes(term) ||
              item.inbound_title.toLowerCase().includes(term) ||
              item.notes.toLowerCase().includes(term) ||
              item.internal_notes.toLowerCase().includes(term)
            );
            if (itemMatch || inboundMatch) {
              return { type: itemMatch ? 'item' : 'inbound', data: item, searchTerms };
            }
            return null;
          });
        })
        .filter(result => result !== null)
        .slice(0, 20);
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm, data]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleGenerateLabels = () => {
    const doc = new jsPDF({
        orientation: 'landscape', // Set landscape orientation
        unit: 'in', // Set units to inches
        format: [2, 1] // Set the format to 2"x1"
    });

    const labels = [];

    // Generate labels based on the shelf inputs
    for (let i = 1; i <= parseInt(shelfHeight); i++) {
        for (let j = 1; j <= parseInt(shelfDepth); j++) {
            const baseLabel = `${shelfAisle}-${i.toString().padStart(2, '0')}-${j.toString().padStart(2, '0')}`;
            labels.push(baseLabel); // Base label
        }
    }

    labels.forEach((label, index) => {
        // Add a new page for every label
        if (index > 0) {
            doc.addPage();
        }

        // Calculate the maximum font size that fits in the label
        let fontSize = 36;
        doc.setFontSize(fontSize);
        let textWidth = doc.getTextWidth(label);
        
        // Reduce font size until the text fits within the label width with some padding
        while (textWidth > 1.8 && fontSize > 10) {
            fontSize -= 1;
            doc.setFontSize(fontSize);
            textWidth = doc.getTextWidth(label);
        }

        // Calculate the center position for the text
        const x = 1; // Center horizontally at 1 inch (half of 2-inch width)
        const y = 0.5 + (fontSize / 72) / 2; // Center vertically at 0.5 inches (half of 1-inch height)

        // Add the label text
        doc.text(label, x, y, { align: 'center' });
    });

    // Save the PDF
    doc.save('shelf_labels.pdf');
    handleCloseDialog();
};



  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Inbound Search
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Print Shelf Code Labels
      </Button>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginTop: '16px' }}
      />
      <div style={{ marginTop: '16px' }}>
        {filteredResults.length > 0 ? (
          filteredResults.map((result, index) => {
            const { data, searchTerms } = result;
            return (
              <StyledCard 
                key={index} 
                variant="outlined"
                onClick={() => window.open(`/inbounds/${data.INBOUND_ID}`, '_blank')} // Open in new tab
                >
                {data.LARGE_IMG && (
                  <Thumbnail src={data.LARGE_IMG} alt="Product Image" />
                )}
                <CardContent>
                  <Typography variant="h6" dangerouslySetInnerHTML={{__html: highlightMatch(data.inbound_title, searchTerms)}} /> 
                  <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: highlightMatch(data.client_name, searchTerms)}} />
                  <Typography variant="subtitle2">Status: {data.status.toUpperCase()}</Typography>
                  <Typography variant="body2" color="textSecondary">Inbound ID: {data.INBOUND_ID}</Typography>
                  {result.type === 'item' && (
                    <>
                      <Typography variant="body1" dangerouslySetInnerHTML={{__html: highlightMatch(data.product_title, searchTerms)}} />
                      <Typography variant="body2">
                        ASIN: {data.ASIN} | FNSKU: {data.FNSKU} | SKU: {data.SKU} | Qty: {data.ACTUAL}/{data.EXPECTED}    
                      </Typography>
                    </>
                  )}
                  {data.notes && (
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: highlightMatch(data.notes, searchTerms)}} />
                  )}
                  {data.internal_notes && (
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: highlightMatch(data.internal_notes, searchTerms)}} />
                  )}
                  <Typography variant="caption">Received At: {data.received_at}</Typography>
                </CardContent>
                <Divider />
              </StyledCard>
            );
          })
        ) : (
          <Typography variant="body1">No results found</Typography>
        )}
      </div>
      
      {/* Dialog for shelf code input */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Print Shelf Code Labels</DialogTitle>
        <DialogContent>
          <TextField
            label="Shelf Aisle (e.g., A)"
            variant="outlined"
            fullWidth
            value={shelfAisle}
            onChange={(e) => setShelfAisle(e.target.value.toUpperCase())}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Shelf Height (e.g., 4)"
            variant="outlined"
            fullWidth
            type="number"
            value={shelfHeight}
            onChange={(e) => setShelfHeight(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Shelf Depth (e.g., 6)"
            variant="outlined"
            fullWidth
            type="number"
            value={shelfDepth}
            onChange={(e) => setShelfDepth(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleGenerateLabels} color="primary">
            Generate PDF
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InboundSearch;
