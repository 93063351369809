import React, { useState, useContext, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Container } from '@mui/material';
import { USAProvider, USAContext } from './USAContext';
import ReviewItemsBoxes from './ReviewItemsBoxes';
import FoodFDACheck from './FoodFDACheck';
import ValidateAndPurchaseLabels from './ValidateAndPurchaseLabels'; // Import the merged component

const USAHelper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [isStepValidated, setIsStepValidated] = useState(false);
    const steps = ['Review Items and Boxes', 'Food/FDA Check', 'Validate & Purchase Labels'];

    const { handleNext, handleBack, handleReset } = useContext(USAContext);

    useEffect(() => {
        if (activeStep !== 2) {
            setIsStepValidated(true);
        } else {
            setIsStepValidated(false); // Reset validation for step 2
        }
    }, [activeStep]);

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <ReviewItemsBoxes />;
            case 1:
                return <FoodFDACheck />;
            case 2:
                return <ValidateAndPurchaseLabels setIsStepValidated={setIsStepValidated} />; // Use the merged component
            default:
                return 'Unknown step';
        }
    };

    const handleNextClick = () => {
        handleNext();
        setActiveStep((prev) => prev + 1);
    };

    const handleBackClick = () => {
        handleBack();
        setActiveStep((prev) => prev - 1);
    };

    return (
        <USAProvider>
            <Container>
                <Stepper activeStep={activeStep} sx={{ my: 4 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ my: 4 }}>
                    {activeStep === steps.length ? (
                        <Box>
                            <Typography>All steps completed</Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    ) : (
                        <Box>
                            {getStepContent(activeStep)}
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBackClick}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleNextClick}
                                    disabled={!isStepValidated}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </USAProvider>
    );
};

export default USAHelper;
