import React from 'react';
import { Typography } from '@mui/material';

function InboundMain() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Inbound Main Page
      </Typography>
      <Typography variant="body1">
        This is where you'll list all the inbounds.
      </Typography>
    </div>
  );
}

export default InboundMain;
