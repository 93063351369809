import React, { useState, useEffect } from 'react';
import {
  TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress,
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Ensure your environment variables are set

function DeliveryLog() {
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(100); // Set rows per page to 100
  const [selectedTrackingCode, setSelectedTrackingCode] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showPalletScans, setShowPalletScans] = useState(false); // Filter for Pallet scans
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    fetchDeliveryLogs();
  }, []);

  const fetchDeliveryLogs = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/delivery_log`);
      setLogs(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching delivery logs:', error);
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/api/delivery_log/${selectedTrackingCode}`);
      setLogs(logs.filter(log => log.TRACKING_CODE !== selectedTrackingCode));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting log:', error);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedLogs = [...logs].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredLogs = sortedLogs.filter((log) =>
    (log.TRACKING_CODE.toLowerCase().includes(searchTerm) ||
      log.EMPLOYEE?.toLowerCase().includes(searchTerm) ||
      log.LOCATION?.toLowerCase().includes(searchTerm) ||
      log.COMPANY?.toLowerCase().includes(searchTerm) ||
      log.SUPPLIER?.toLowerCase().includes(searchTerm) ||
      log.NOTES?.toLowerCase().includes(searchTerm)) &&
    (!showPalletScans || log.TRACKING_CODE.toLowerCase().includes('pallet'))
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Delivery Log
      </Typography>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: '16px' }}
        placeholder="Search by Tracking Code, Employee, Location, etc."
      />

      <Button
        variant="contained"
        color={showPalletScans ? 'primary' : 'secondary'}
        onClick={() => setShowPalletScans(!showPalletScans)}
        style={{ marginBottom: '16px' }}
      >
        {showPalletScans ? 'Show All Scans' : 'View Pallet Scans'}
      </Button>

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
                  onClick={() => handleSort('TRACKING_CODE')}
                >
                  Tracking Code {sortConfig.key === 'TRACKING_CODE' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('EMPLOYEE')} style={{ cursor: 'pointer' }}>
                  Employee {sortConfig.key === 'EMPLOYEE' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('TIME_OF_SCAN')} style={{ cursor: 'pointer' }}>
                  Time of Scan {sortConfig.key === 'TIME_OF_SCAN' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('LOCATION')} style={{ cursor: 'pointer' }}>
                  Location {sortConfig.key === 'LOCATION' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('COMPANY')} style={{ cursor: 'pointer' }}>
                  Company {sortConfig.key === 'COMPANY' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('SUPPLIER')} style={{ cursor: 'pointer' }}>
                  Supplier {sortConfig.key === 'SUPPLIER' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('NOTES')} style={{ cursor: 'pointer' }}>
                  Notes {sortConfig.key === 'NOTES' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log) => (
                <TableRow key={log.TRACKING_CODE}>
                  <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{log.TRACKING_CODE}</TableCell>
                  <TableCell>{log.EMPLOYEE}</TableCell>
                  <TableCell>{new Date(log.TIME_OF_SCAN).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} at {new Date(log.TIME_OF_SCAN).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                  <TableCell>{log.LOCATION}</TableCell>
                  <TableCell>{log.COMPANY}</TableCell>
                  <TableCell>{log.SUPPLIER}</TableCell>
                  <TableCell>{log.NOTES}</TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={() => { setSelectedTrackingCode(log.TRACKING_CODE); setOpenDeleteDialog(true); }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredLogs.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        </TableContainer>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Log</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this delivery log? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeliveryLog;
