import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography, Container, CircularProgress } from '@mui/material';
import { USAContext } from './USAContext';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';

const API_URL = process.env.REACT_APP_API_URL;

const ValidateAndPurchaseLabels = () => {
    const { shipmentInfo, clientsData, boxes, asinData, selectedPlan } = useContext(USAContext);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isValidated, setIsValidated] = useState(false); // State to track if the validation step is complete
    const [credits, setCredits] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [clientName, setClientName] = useState("");
    const [requiredCredits, setRequiredCredits] = useState(0);
    const [purchaseAmount, setPurchaseAmount] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [labels, setLabels] = useState([]);
    const [purchaseComplete, setPurchaseComplete] = useState(false);
    const [stallionPdfBytes, setStallionPdfBytes] = useState(null);
    const [stallionFile, setStallionFile] = useState(null);
    const [upsFile, setUpsFile] = useState(null);
    useEffect(() => {
        if (selectedPlan) {
            setAddress(selectedPlan.destination_address.address_line_1 || '');
            setCity(selectedPlan.destination_address.city || '');
            setState(selectedPlan.destination_address.state_or_province_code || '');
            setZip(selectedPlan.destination_address.postal_code || '');
            fetchLabels();
        }
    }, [selectedPlan]);


    const fetchLabels = async () => {
        if (!selectedPlan) return;
    
        try {
            const response = await fetch(`https://app.yyzprep.ca/api/fba-transport/v0/plans/${selectedPlan.id}/labels?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
            const data = await response.json();
            
            const label = data.data.find(label => label.name.includes('4" x 6"')) || 
                          data.data.find(label => label.name.includes('8.5" x 11"'));
    
            if (label) {
                setPdfUrl(label.url);
                console.log("Set PDF URL to ", label.url);
            } else {
                setPdfUrl('No UPS Label Found');
            }
        } catch (error) {
            console.error('Error fetching labels:', error);
            setPdfUrl('No UPS Label Found');
        }
    };
    
    
    const handleUploadAndMerge = async () => {
        if (!stallionFile || !upsFile) {
            console.error("Both Stallion and UPS labels must be uploaded");
            return;
        }
    
        try {
            const mergedPdf = await PDFDocument.create();
    
            // Read Stallion PDF file
            const stallionPdfBytes = await stallionFile.arrayBuffer();
            const stallionPdf = await PDFDocument.load(stallionPdfBytes);
            const stallionPages = await mergedPdf.copyPages(
                stallionPdf,
                stallionPdf.getPageIndices()
            );
    
            // Read UPS PDF file
            const upsPdfBytes = await upsFile.arrayBuffer();
            const upsPdf = await PDFDocument.load(upsPdfBytes);
            const upsPages = await mergedPdf.copyPages(
                upsPdf,
                upsPdf.getPageIndices()
            );
    
            // Merge UPS and Stallion pages: 2 UPS, then 1 Stallion
            let upsIndex = 0;
            let stallionIndex = 0;
            const totalUpsPages = upsPages.length;
            const totalStallionPages = stallionPages.length;
    
            while (upsIndex < totalUpsPages || stallionIndex < totalStallionPages) {
                // Add up to 2 UPS pages
                for (let i = 0; i < 2 && upsIndex < totalUpsPages; i++) {
                    mergedPdf.addPage(upsPages[upsIndex]);
                    upsIndex++;
                }
    
                // Add 1 Stallion page
                if (stallionIndex < totalStallionPages) {
                    mergedPdf.addPage(stallionPages[stallionIndex]);
                    stallionIndex++;
                }
            }
    
            const mergedPdfBytes = await mergedPdf.save();
            saveAs(
                new Blob([mergedPdfBytes], { type: "application/pdf" }),
                `MERGED ${selectedPlan.fba_shipment_id}_${selectedPlan.name}.pdf`

            );
        } catch (error) {
            console.error("Error merging labels:", error);
        }
    };
    
    
    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleZipChange = (event) => {
        setZip(event.target.value);
    };

    const handleValidate = async () => {
        if (address && city && state && zip) {
            setIsValidated(true);
    
            // Fetch API key and credits after validation
            if (shipmentInfo && clientsData) {
                const client = clientsData.find(client => client.id === shipmentInfo.team_id);
                if (client) {
                    await fetchApiKey(client.name);
                } else {
                    setError("No client found for this shipment.");
                }
            }
        }
    };
    

    const fetchApiKey = async (clientName) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/api/getStallionApiKey`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ clientName }),
            });
            const data = await response.json();

            if (data.apiKey) {
                setApiKey(data.apiKey);
                setClientName(clientName);
                setError("");
            } else {
                setError("API key not found for client: " + clientName);
            }
        } catch (error) {
            console.error("Error fetching credits:", error);
            setError("Failed to fetch credits. Please try again.");
            alert("No Stallion Account Located, is this their first US shipment?");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (boxes.length > 0) {
            calculateRequiredCredits(boxes);
        }
    }, [boxes]);
// useEffect that triggers when apiKey changes
useEffect(() => {
    fetchCredits();
}, [apiKey]); // Only re-run the effect if apiKey changes

    const fetchCredits = async () => {
        if (!apiKey) return; // Don't fetch if apiKey is not set

        try {
            const creditsResponse = await fetch(`${API_URL}/api/fetch-credits`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ apiKey: apiKey }), // Replace with your actual API key
            });
    
            if (!creditsResponse.ok) {
                throw new Error("Failed to fetch credits.");
            }
    
            const creditsData = await creditsResponse.json();
            setCredits(creditsData.credits);
            console.log(credits);
        } catch (error) {
            console.error("Error fetching credits:", error);
            setError("Failed to fetch credits. Please try again.");
        }
    };
    
    

    const handlePurchaseCredits = async () => {
        if (purchaseAmount < 10) {
            setError("Minimum purchase amount is $10");
            return;
        }
    
        setPurchaseLoading(true);
        try {
            const response = await fetch(`${API_URL}/api/purchase-credits`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    apiKey: apiKey, // Replace with your actual API key
                    amount: parseFloat(purchaseAmount).toFixed(2),
                }),
            });
    
            const purchaseData = await response.json();
    
            if (response.ok && purchaseData.success) {
                fetchCredits(); // Re-fetch credits after purchase
                setPurchaseAmount(""); // Clear purchase amount input
            } else {
                setError("Failed to purchase credits. Please try again.");
            }
        } catch (error) {
            console.error("Error purchasing credits:", error);
            setError("Failed to purchase credits. Please try again.");
        } finally {
            setPurchaseLoading(false);
        }
    };
    

    
    const calculateRequiredCredits = (boxes) => {
        let runningTotal = 0;
        boxes.forEach((box) => {
            const weightLbs = box.weight * 2.20462;
            let amount = 0;

            if (weightLbs < 10) {
                amount = 3.99;
            } else if (weightLbs >= 10 && weightLbs < 20) {
                amount = 5.99;
            } else if (weightLbs >= 20 && weightLbs < 40) {
                amount = 9.99;
            } else {
                amount = 12.99;
            }

            runningTotal += amount;
        });

        setRequiredCredits(parseFloat(runningTotal.toFixed(2)));
    };

    const handlePurchaseLabels = async () => {
        setPurchaseLoading(true);
        console.log(boxes);
        try {
            const boxesWithValues = boxes.map(box => {
                // Calculate the value for each item in the box
                const itemsWithPrices = box.items.map(item => ({
                    ...item,
                    value: getPrice(item.ASIN) // Assume getPrice is a function that calculates the price
                }));
    
                // Calculate the total box value
                const boxValue = itemsWithPrices.reduce((total, item) => total + item.value * item.QTY, 0);
    
                // Return the box object with the calculated boxValue and items with prices
                return {
                    ...box,
                    boxValue,
                    items: itemsWithPrices
                };
            });
    
            // Validate that all boxes have valid boxValue
            const validBoxes = boxesWithValues.filter(box => box.boxValue != null);
    
            if (validBoxes.length !== boxes.length) {
                console.error('Some boxes have invalid boxValue. Please check your calculation logic.');
                setError('Failed to calculate box values. Please check your items and try again.');
                setPurchaseLoading(false);
                return;
            }
    
            const response = await fetch(`${API_URL}/api/purchase-labels`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    boxes: boxesWithValues,
                    clientName,
                    address,
                    city,
                    state,
                    zip,
                    selectedPlan,
                    apiKey: apiKey, // Or retrieve it securely
                }),
            });
    
            const data = await response.json();
        if (response.ok) {
            setLabels(data.labels);

            // Decode Base64 PDF data and create a Blob
            const pdfBinary = atob(data.pdfBase64); // Decode Base64
            const pdfArray = Uint8Array.from(pdfBinary, char => char.charCodeAt(0));
            const blob = new Blob([pdfArray], { type: "application/pdf" });

            // Trigger download
            saveAs(blob, `USA Labels ${selectedPlan.fba_shipment_id}_${selectedPlan.name}.pdf`);
            setPurchaseComplete(true);

        } else {
            setError(data.error || "Failed to purchase labels. Please try again.");
        }
    } catch (error) {
        console.error("Error purchasing labels:", error);
        setError("Failed to purchase labels. Please try again.");
    } finally {
        fetchCredits();
        setPurchaseLoading(false);
    }
};
    
    const getPrice = (asin) => {
        const data = asinData.find((item) => item.ASIN === asin);
        return data ? parseFloat(data.VALUE) : 0;
    };

    const calculateBoxValue = (box) => {
        return box.items.reduce((acc, item) => {
            return acc + getPrice(item.ASIN) * item.QTY;
        }, 0);
    };

    return (
        <Container>
            <Grid container spacing={3}>
                {/* Step 1: Validation Section */}
                <Grid item xs={12}>
                    <Typography variant="h5">Marketplace Data</Typography>
                    <Paper style={{ padding: '16px', marginBottom: '8px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Address"
                                    fullWidth
                                    value={address}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="City"
                                    fullWidth
                                    value={city}
                                    onChange={handleCityChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="State"
                                    fullWidth
                                    value={state}
                                    onChange={handleStateChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Zip"
                                    fullWidth
                                    value={zip}
                                    onChange={handleZipChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" marginTop={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleValidate}
                                disabled={!address || !city || !state || !zip}
                            >
                                Validate
                            </Button>
                        </Box>
                    </Paper>
                    <Grid item xs={12} md={6}>
    <Typography variant="h5">UPS Labels</Typography>
    <Box display="flex" justifyContent="flex-end" marginTop={3}>
    <Button
        variant="contained"
        color="primary"
        onClick={fetchLabels} // Trigger the refresh
    >
        Refresh UPS Labels
    </Button>
</Box>

    <Paper style={{ padding: '16px', marginBottom: '8px' }}>
        {pdfUrl && pdfUrl !== 'No UPS Label Found' ? (
            <embed
                src={pdfUrl}
                width="100%"
                height="500px"
                type="application/pdf"
                style={{ border: 'none' }}
            />
        ) : (
            <Typography>No UPS Label Found</Typography>
        )}
    </Paper>
</Grid>

                </Grid>

 {/* Step 2: Purchase Labels Section */}
 {isValidated && (
    
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" marginTop={2}>
    <TextField
        label="Purchase Credits"
        value={purchaseAmount}
        onChange={(e) => setPurchaseAmount(e.target.value)}
        required
        helperText="Minimum $10"
        style={{ width: "180px", marginRight: "16px" }}
        inputProps={{ maxLength: 6 }}
    />
    <Button
        variant="contained"
        color="primary"
        onClick={handlePurchaseCredits}
        disabled={purchaseLoading || purchaseAmount < 10}
    >
        {purchaseLoading ? (
            <CircularProgress size={24} />
        ) : (
            "Purchase Credits"
        )}
    </Button>
</Box>

                    <Typography variant="h5">Purchase Labels</Typography>
                    <Paper style={{ padding: '16px', marginBottom: '8px' }}>
                        {loading ? (
                            <CircularProgress />
                        ) : credits !== null ? (
                            <>
                                <Typography variant="h6">
                                    API Key Found for client: {clientName}
                                </Typography>
                                <Typography variant="body1">
                                    Available Credits: ${credits}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: credits >= requiredCredits ? "green" : "red",
                                    }}
                                >
                                    Required Credits: ${requiredCredits}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" marginTop={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handlePurchaseLabels}
                                        disabled={credits < requiredCredits || purchaseLoading}
                                    >
                                        {purchaseLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            "Purchase Labels"
                                        )}
                                    </Button>
                                    
                                    <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item>
                <Typography variant="h6">Upload Stallion Label</Typography>
                <TextField
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setStallionFile(e.target.files[0])}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />
            </Grid>

            <Grid item>
                <Typography variant="h6">Upload UPS Label</Typography>
                <TextField
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setUpsFile(e.target.files[0])}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />
            </Grid>

            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadAndMerge}
                >
                    Upload and Merge Labels
                </Button>
            </Grid>
        </Grid>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="h6" color="error">
                                {error}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            )}
        </Grid>
        {purchaseComplete && (
            <Box marginTop={4}>
                <Typography variant="h6">Labels purchased successfully!</Typography>
            </Box>
        )}
        </Container>
    );
};

export default ValidateAndPurchaseLabels;
