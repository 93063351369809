import React, { useState, useEffect, useContext } from 'react';
import { BoxContext } from './BoxContext';
import { Modal, TextField, Button, Box, Typography, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const BoxDimensions = ({ boxNumber, shipmentId, handleDimensionBlur, selectedBox }) => {
  const { boxes, refreshBoxes, asinData, standardBoxes, refreshStandardBoxes } = useContext(BoxContext);
  const box = boxes.find((x) => x.boxNumber == boxNumber);
  const API_URL = process.env.REACT_APP_API_URL;

  const [tempDetails, setTempDetails] = useState({
    LENGTH: '',
    WIDTH: '',
    HEIGHT: '',
    WEIGHT: '',
  });

  const [showNewBoxModal, setShowNewBoxModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [boxToDelete, setBoxToDelete] = useState(null);
  const [selectedBoxName, setSelectedBoxName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [newBoxDetails, setNewBoxDetails] = useState({
    NAME: '',
    LENGTH: '',
    WIDTH: '',
    HEIGHT: ''
  });

  useEffect(() => {
    if (box) {
      setTempDetails({
        LENGTH: box.length || '',
        WIDTH: box.width || '',
        HEIGHT: box.height || '',
        WEIGHT: box.weight || '',
      });
    }
  }, [box]);

  const calculateTotalWeight = () => {
    if (!selectedBox || !Array.isArray(selectedBox.items) || !Array.isArray(asinData)) {
      return 0;
    }

    const itemsTotalWeight = selectedBox.items.reduce((acc, item) => {
      const asinItem = asinData.find((asinItem) => asinItem.ASIN === item.ASIN);
      if (!asinItem) return acc;
      const itemWeight = asinItem.WEIGHT * item.QTY;
      return acc + itemWeight;
    }, 0);

    const boxWeight = 1; // Fixed weight for the box itself
    const totalWeight = itemsTotalWeight + boxWeight;
    return totalWeight.toFixed(2); // Round to two decimal places
  };

  const totalWeight = calculateTotalWeight();

  const handleUseCalculatedWeight = async () => {
    await handleDimensionBlur(tempDetails.LENGTH, tempDetails.WIDTH, tempDetails.HEIGHT, totalWeight);
    await refreshBoxes(); // Reload values after the blur event

    // Update local state after the context is refreshed
    if (box) {
      setTempDetails((prev) => ({ ...prev, WEIGHT: totalWeight }));
    }
  };

  const handleBlur = async () => {
    await handleDimensionBlur(tempDetails.LENGTH, tempDetails.WIDTH, tempDetails.HEIGHT, tempDetails.WEIGHT);
    await refreshBoxes(); // Reload values after the blur event
  };

  const handleChange = (field, value) => {
    setTempDetails((prev) => ({ ...prev, [field]: value }));
  };

  const handleCopyPreviousBoxDimensions = async () => {
    const previousBox = boxes.find((x) => x.boxNumber == boxNumber - 1);
  
    if (previousBox) {
      const updatedDetails = {
        LENGTH: previousBox.length,
        WIDTH: previousBox.width,
        HEIGHT: previousBox.height,
        WEIGHT: previousBox.weight,
      };
  
      setTempDetails(updatedDetails);
  
      // Save all dimensions with a single backend call
      await handleDimensionBlur(updatedDetails.LENGTH, updatedDetails.WIDTH, updatedDetails.HEIGHT, updatedDetails.WEIGHT);
      await refreshBoxes(); // Ensure state is updated after copying dimensions
    }
  };

  const handleNewBoxChange = (field, value) => {
    setNewBoxDetails((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveNewBox = async () => {
    try {
      const response = await fetch(`${API_URL}/api/add_standard_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBoxDetails),
      });

      const data = await response.json();

      if (data.success) {
        // Close the modal
        setShowNewBoxModal(false);

        // Clear the input fields
        setNewBoxDetails({
          NAME: '',
          LENGTH: '',
          WIDTH: '',
          HEIGHT: ''
        });

        // Refresh the standard boxes list
        refreshStandardBoxes();
      } else {
        console.error('Failed to add box:', data.error);
      }
    } catch (error) {
      console.error('Error adding box:', error);
    }
  };

  const handleDeleteBox = async () => {
    if (!boxToDelete) return;
  
    try {
      const response = await fetch(`${API_URL}/api/delete_standard_box`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ NAME: boxToDelete }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Close the confirmation dialog
        setShowDeleteDialog(false);
        setBoxToDelete(null);
  
        // Refresh the standard boxes list
        refreshStandardBoxes();
      } else {
        console.error('Failed to delete box:', data.error);
      }
    } catch (error) {
      console.error('Error deleting box:', error);
    }
  };
  

  const handlePresetBoxSelect = (name) => {
    setSelectedBoxName(name);
    const selectedPresetBox = standardBoxes.find((box) => box.NAME === name);
    if (selectedPresetBox) {
      const updatedDetails = {
        LENGTH: selectedPresetBox.LENGTH,
        WIDTH: selectedPresetBox.WIDTH,
        HEIGHT: selectedPresetBox.HEIGHT,
        WEIGHT: tempDetails.WEIGHT, // keep current weight
      };

      setTempDetails(updatedDetails);

      // Save all dimensions with a single backend call
      handleDimensionBlur(updatedDetails.LENGTH, updatedDetails.WIDTH, updatedDetails.HEIGHT, updatedDetails.WEIGHT);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (name) => {
    setBoxToDelete(name);
    setShowDeleteDialog(true);
    handleMenuClose();
  };

  return (
    <div className="grid grid-cols-1 gap-4 mb-4">
      <div className="form-group mb-4">
        <label htmlFor="presetBox">Preset Box:</label>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            variant="outlined"
            endIcon={<MoreVertIcon />}
          >
            {selectedBoxName || "Select a preset box"}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {standardBoxes.map((presetBox, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handlePresetBoxSelect(presetBox.NAME);
                }}
              >
                {presetBox.NAME}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(presetBox.NAME);
                  }}
                  size="small"
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setShowNewBoxModal(true)}>Add New Box</MenuItem>
          </Menu>
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Length Input */}
        <div>
          <label className="block mb-2">Length (cm)</label>
          <input
            type="number"
            value={tempDetails.LENGTH}
            onChange={(e) => handleChange('LENGTH', e.target.value)}
            onBlur={handleBlur}
            className="w-20 p-2 border border-gray-300 rounded"
            placeholder="Length"
          />
        </div>
        {/* Width Input */}
        <div>
          <label className="block mb-2">Width (cm)</label>
          <input
            type="number"
            value={tempDetails.WIDTH}
            onChange={(e) => handleChange('WIDTH', e.target.value)}
            onBlur={handleBlur}
            className="w-20 p-2 border border-gray-300 rounded"
            placeholder="Width"
          />
        </div>
        {/* Height Input */}
        <div>
          <label className="block mb-2">Height (cm)</label>
          <input
            type="number"
            value={tempDetails.HEIGHT}
            onChange={(e) => handleChange('HEIGHT', e.target.value)}
            onBlur={handleBlur}
            className="w-20 p-2 border border-gray-300 rounded"
            placeholder="Height"
          />
        </div>
        {/* Weight Input */}
        <div>
          <label className="block mb-2">Weight (kg)</label>
          <div className="flex space-x-2">
            <input
              type="number"
              value={tempDetails.WEIGHT}
              onChange={(e) => handleChange('WEIGHT', e.target.value)}
              onBlur={handleBlur}
              className="w-20 p-2 border border-gray-300 rounded"
              placeholder="Weight"
            />
            <button onClick={handleUseCalculatedWeight} className="bg-blue-500 text-white p-2 rounded">
              Use Calculated Weight ({totalWeight} Kgs)
            </button>
          </div>
        </div>
      </div>

      {/* Copy box dimensions button */}
      {boxNumber > 1 && ( // Only show if the box number is greater than 1
        <button onClick={handleCopyPreviousBoxDimensions} className="bg-blue-500 text-white p-2 rounded mt-4">
          Copy Dimensions from Previous Box
        </button>
      )}

      {/* Material-UI Modal for Adding New Box */}
      <Modal
        open={showNewBoxModal}
        onClose={() => setShowNewBoxModal(false)}
        aria-labelledby="add-new-box-modal"
        aria-describedby="modal-for-adding-new-box"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" component="h2" id="add-new-box-modal">
            Add New Box
          </Typography>
          <TextField
            label="Box Name"
            fullWidth
            margin="normal"
            value={newBoxDetails.NAME}
            onChange={(e) => handleNewBoxChange('NAME', e.target.value)}
          />
          <TextField
            label="Length (cm)"
            fullWidth
            margin="normal"
            type="number"
            value={newBoxDetails.LENGTH}
            onChange={(e) => handleNewBoxChange('LENGTH', e.target.value)}
            />
            <TextField
              label="Width (cm)"
              fullWidth
              margin="normal"
              type="number"
              value={newBoxDetails.WIDTH}
              onChange={(e) => handleNewBoxChange('WIDTH', e.target.value)}
            />
            <TextField
              label="Height (cm)"
              fullWidth
              margin="normal"
              type="number"
              value={newBoxDetails.HEIGHT}
              onChange={(e) => handleNewBoxChange('HEIGHT', e.target.value)}
            />
            <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="contained" color="primary" onClick={handleSaveNewBox}>
                Save
              </Button>
              <Button variant="contained" color="secondary" onClick={() => setShowNewBoxModal(false)}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Dialog for Delete Confirmation */}
        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          aria-labelledby="delete-box-dialog"
          aria-describedby="confirm-box-deletion"
        >
          <DialogTitle id="delete-box-dialog">Delete Box</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-box-deletion">
              Are you sure you want to delete the box "{boxToDelete}"? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteBox} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default BoxDimensions;
