import React, { useState, useRef, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from 'react-select';
import OutboundItem from './OutboundItem';
import Box from './Box';
import { Modal } from '@mui/material';

import ImportModal from './ImportModal';
import { BoxContext } from './BoxContext';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { BoxProvider } from './BoxContext';
import SendEmail from './SendEmail';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import NicknameModal from './NicknameModal';
import PickingListModal from './PickingListModal'

import { Button, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';  // Import LinearProgress

import { update } from 'lodash';
import ReassignPlanModal from './ReassignPlanModal';
const moment = require('moment');
const API_URL = process.env.REACT_APP_API_URL;

const Shipment = () => {
  const { shipmentId } = useParams();

  return (
    <BoxProvider shipmentId={shipmentId}>
      <ShipmentContent />
    </BoxProvider>
  );
};

const ShipmentContent = () => {
  const fileInputRef = useRef(null); // Create a ref for the file input

  const [dataLoaded, setDataLoaded] = useState(false);
  const { shipmentId } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'info', 'success', 'error', etc.
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const { 
    shipmentInfo, 
    shipmentItems, 
    shelvingData, 
    reloadShipmentData,
    servicesData, 
    clientsData, 
    clientInfo, 
    fetchAsinData,
    loading, 
    removeShelf,
    updateShelvingQty,
    removeShelving,
    fetchAllData,
    boxes, 
    setSelectedNickname,
    selectedNickname,
    setBoxes, 
    fetchStandardBoxes,
    refreshBoxes,
    plans, 
    selectedPlan,
    setSelectedPlan,
  } = useContext(BoxContext);
// Add this line in ShipmentContent component
const [openBoxNumber, setOpenBoxNumber] = useState(null);
const [oversizedItemsWithoutFees, setOversizedItemsWithoutFees] = useState([]);
const [isModalOpen, setIsModalOpen] = useState(false);
const [showShelfReminder, setShowShelfReminder] = useState(false);

  const [asinData, setAsinData] = useState([]);
  const [selectedBox, setSelectedBox] = useState(null);
  const [itemSearchTerm, setItemSearchTerm] = useState('');
  const [boxSearchTerm, setBoxSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [standardBoxes, setStandardBoxes] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalNotes, setInternalNotes] = useState('');
  const [importedData, setImportedData] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showPickingListModal, setShowPickingListModal] = useState(false);
  const [pickingListData, setPickingListData] = useState([]);
  const [showItemsForAllSplits, setShowItemsForAllSplits] = useState(false);
  const [showReassignPlanModal, setShowReassignPlanModal] = useState(false);
  const [mainOutboundId, setMainOutboundId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResetDialog, setShowResetDialog] = useState(false); // State to control the dialog visibility
  const [isResetting, setIsResetting] = useState(false); // State to manage the loading state during the reset
// State for progress
const [unitProgress, setUnitProgress] = useState(0);
const [skuProgress, setSkuProgress] = useState(0)
const [unitsPacked, setUnitsPacked] = useState(0);
const [skusCompleted, setSkusCompleted] = useState(0);
const [totalUnits, setTotalUnits] = useState(0);
const checkOversizedItemsWithoutFees = () => {
  console.log("Checking for oversized items without fees...");

  const itemsWithoutFees = shipmentItems.filter(item => {
    const lengthInches = item.item.length_mm / 25.4;
    const widthInches = item.item.width_mm / 25.4;
    const heightInches = item.item.height_mm / 25.4;
    const weightLbs = item.item.weight_gm / 453.592;

    const isOversized = lengthInches > 18 || widthInches > 14 || heightInches > 8 || weightLbs > 20;
    const isHugeOversized = isOversized && (lengthInches >= 25 || widthInches >= 25 || heightInches >= 25);

    let expectedFee = '';
    if (isHugeOversized) expectedFee = 'Huge Oversized';
    else if (isOversized) expectedFee = 'Oversized';

    if (expectedFee) {
      console.log(`Item: ${item.item.title} (SKU: ${item.item.merchant_sku}) is expected to have fee: ${expectedFee}`);
      
      const hasFee = item.company_services.some(service => service.name === expectedFee && service.fee);
      if (!hasFee) {
        console.log(`  - Missing fee for ${expectedFee}`);
        return true; // Item is oversized but does not have the required fee
      }
    }

    return false; // Item is either not oversized or has the required fee
  });

  if (itemsWithoutFees.length > 0) {
    console.log("Oversized items without fees found:", itemsWithoutFees);
    setOversizedItemsWithoutFees(itemsWithoutFees);
    setIsModalOpen(true);
    return false; // Stop the submission
  }

  console.log("All oversized items have the correct fees applied.");
  return true; // Allow the submission to proceed
};


  useEffect(() => {
    if (shipmentInfo) {
      setInternalNotes(shipmentInfo.internal_notes);
    }
  }, [shipmentInfo]);

  const handleResetBoxContents = async () => {
    try {
      setIsResetting(true); // Start loading state

      const response = await fetch(`${API_URL}/api/reset_box_contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          FBA_ID: selectedPlan.fba_shipment_id, // Assuming FBA_ID is part of selectedPlan
          selectedNickname, // Employee performing the action
        }),
      });

      if (response.ok) {
        console.log('Box contents reset successfully');
        refreshBoxes();
        // Handle any post-reset logic here (e.g., show a success message, refresh data)
      } else {
        console.error('Failed to reset box contents');
      }
    } catch (error) {
      console.error('Error resetting box contents:', error);
    } finally {
      setIsResetting(false); // End loading state
      setShowResetDialog(false); // Close the dialog
    }
  };


  const handlePlanSelect = (selectedOption) => {
    if (plans.length === 1) {
      // Automatically select the only plan available
      const plan = plans[0];
      setSelectedPlan(plan);
      console.log('Selected plan:', plan);
    } else {
      // Handle the selection based on the user's choice
      const plan = plans.find(p => p.id === selectedOption.value);
      setSelectedPlan(plan ? plan : null);
      console.log('Selected plan:', plan);
    }
    refreshBoxes();
  };
  



  
  const handleReassignPlan = async (outboundId, newPlanId) => {
    try {
      // Construct the new OUTBOUND_ID
      const newOutboundId = `${newPlanId}`;
  
      const response = await fetch(`${API_URL}/api/reassign_plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shipmentId, outboundId, newPlanId })
      });
  
      if (!response.ok) {
        throw new Error('Failed to reassign plan');
      }
  
      const result = await response.json();
      console.log('Plan reassigned successfully:', result);
  
      // Refresh the box contents after reassignment
      refreshBoxes();
    } catch (error) {
      console.error('Error reassigning plan:', error);
    }
  };
  

  const groupAndSortPlans = (plans) => {
    const grouped = plans.reduce((acc, plan) => {
      const date = new Date(plan.created_at).toLocaleDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(plan);
      return acc;
    }, {});

    const sorted = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b));
    
    return sorted.map(date => ({
      date,
      plans: grouped[date].sort((a, b) => a.name.localeCompare(b.name))
    }));
  };

  const handleConfirmImport = async () => {
    try {
      const replaceBoxContentsBody = { shipmentId, selectedPlan, boxes: importedData, selectedNickname };

      await fetch(`${API_URL}/api/replace_box_contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(replaceBoxContentsBody)
      });
      refreshBoxes();

    // Update expiry dates and other fields for matched items
    for (const item of importedData) {
      const { SKU, EXPIRY_DATE, SERVICES_ADDED } = item;
      const matchedItem = shipmentItems.find(shipmentItem => shipmentItem.item.merchant_sku === SKU);
      if (matchedItem && EXPIRY_DATE) {
        let formattedExpiryDate;

        // Check if EXPIRY_DATE is an Excel serial date (a number)
        if (!isNaN(EXPIRY_DATE) && typeof EXPIRY_DATE === 'number') {
          formattedExpiryDate = convertExcelDateToJSDate(EXPIRY_DATE);
        } else if (!isNaN(Date.parse(EXPIRY_DATE))) {
          // Check if EXPIRY_DATE is already in a valid date format (YYYY-MM-DD)
          formattedExpiryDate = EXPIRY_DATE;
        } else {
          console.error('Invalid date format:', EXPIRY_DATE);
          continue; // Skip this item if the date is invalid
        }

        console.log('Formatted Expiry Date:', formattedExpiryDate);
        // Construct the payload for the update
        const updateItemPayload = {
          itemId: matchedItem.id,
          shipmentId: shipmentId,
          item: {
            ...matchedItem,
            expiry_date: formattedExpiryDate,
          }
        };

        // Call the /api/update-expiry endpoint
        const expiryResponse = await fetch(`${API_URL}/api/update-expiry`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateItemPayload),
        });

        if (expiryResponse.ok) {
          const responseData = await expiryResponse.json();
          console.log('Expiry date updated successfully:', responseData);
        } else {
          console.error('Failed to update expiry date');
        }
      }
    
        // Handle services
        if (SERVICES_ADDED) {
          const services = SERVICES_ADDED.split(',').map(service => service.trim());

          // Match the services with the system's services
          const matchedServices = servicesData.services.filter(systemService =>
            services.includes(systemService.name)
          );

          if (matchedServices.length > 0) {
            const servicesPayload = matchedServices.reduce((acc, service, index) => {
              acc[index] = {
                id: service.id,
                name: service.name,
                charge: service.charge,
                advanced_options: service.advanced_options,
                quantity: 1,
              };
              return acc;
            }, {});

            // Update the services for the item
            const servicesResponse = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/outbound-shipment-item/${matchedItem.id}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                services: servicesPayload
              }),
            });

            if (servicesResponse.ok) {
              console.log(`Services updated successfully for item ${matchedItem.id}`);
            } else {
              console.error(`Failed to update services for item ${matchedItem.id}`);
            }
          } else {
            console.warn(`No matching services found for item ${SKU}`);
          }
        }
      }
      reloadShipmentData();
      setShowImportModal(false);
    } catch (error) {
      console.error('Error confirming import:', error);
      setShowImportModal(false);

    }
  };

  const convertExcelDateToJSDate = (serial) => {
    const excelEpoch = new Date(1899, 11, 30); // Excel's epoch is 1899-12-30
    const jsDate = new Date(excelEpoch.getTime() + (serial * 86400 * 1000));
    return jsDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  };

  const handleImportExcel = async (event) => {
    console.log("Uploaded file...");
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      const boxContentsSheet = workbook.Sheets['Box Contents'];
      const shipmentItemsSheet = workbook.Sheets['Shipment Items'];
  
      const boxContents = XLSX.utils.sheet_to_json(boxContentsSheet);
      const shipmentItems = XLSX.utils.sheet_to_json(shipmentItemsSheet);
  
      const combinedData = boxContents.map(boxItem => {
        const shipmentItem = shipmentItems.find(item => item.SKU === boxItem.SKU) || {};
        return {
          ...boxItem,
          EXPIRY_DATE: shipmentItem.EXPIRY_DATE || '',
          SERVICES_ADDED: shipmentItem.SERVICES_ADDED || '',
          FNSKU: shipmentItem.FNSKU || '',
          ASIN: shipmentItem.ASIN || '',
          LENGTH: boxItem.LENGTH || shipmentItem.LENGTH || '',
          WIDTH: boxItem.WIDTH || shipmentItem.WIDTH || '',
          HEIGHT: boxItem.HEIGHT || shipmentItem.HEIGHT || '',
          WEIGHT: boxItem.WEIGHT || shipmentItem.WEIGHT || '',
        };
      });
  
      const boxNumbers = [...new Set(combinedData.map(item => parseInt(item.BOX_NUMBER)))];
      boxNumbers.sort((a, b) => a - b);
  
      for (let i = 0; i < boxNumbers.length; i++) {
        if (boxNumbers[i] !== i + 1) {
          alert(`Box numbers must be consecutive starting from 1. Missing box number: ${i + 1}`);
          return;
        }
      }
      
    
      setImportedData(combinedData);
      setShowImportModal(true);
      fileInputRef.current.value = null;
    };
  
    reader.readAsArrayBuffer(file);
  };

  

  const updateItemServices = async (item, services) => {
    try {
      // Fetch the available services from your system (if not already loaded)
      const response = await fetch(`https://app.yyzprep.ca/api/services?type[]=outbound_shipment_item&api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
      const servicesData = await response.json();
  
      // Match services from the Excel file with available services in your system
      const matchedServices = servicesData.services.filter(systemService =>
        services.includes(systemService.name)
      );
  
      if (matchedServices.length > 0) {
        const servicesPayload = matchedServices.reduce((acc, service, index) => {
          acc[index] = {
            id: service.id,
            name: service.name,
            charge: service.charge,
            advanced_options: service.advanced_options,
            quantity: 1, // assuming quantity is always 1, adjust if necessary
          };
          return acc;
        }, {});
  
        // Update the item with the matched services
        await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${item.SHIPMENT_ID}/outbound-shipment-item/${item.ITEM_ID}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            services: servicesPayload
          }),
        });
  
        console.log(`Services updated for item ${item.SKU}:`, matchedServices.map(s => s.name).join(', '));
      } else {
        console.warn(`No matching services found for item ${item.SKU}`);
      }
    } catch (error) {
      console.error('Error updating services:', error);
    }
  };


  useEffect(() => {
    refreshBoxes();
  }, [refreshBoxes]);

  let skuList = [];
  const [nicknames, setNicknames] = useState([]);
  const [prepFilter, setPrepFilter] = useState(false);
  const [shelvedFilter, setShelvedFilter] = useState(false);
  const [boxSortOrder, setBoxSortOrder] = useState('asc');

  const handleInternalNotesChange = (e) => {
    setInternalNotes(e.target.value);
  };
  
// Function to toggle lock on a shipment
async function toggleLock(shipmentId) {
  try {
    const response = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/toggleLock?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
      method: 'GET',
    });

    const data = await response.json();
    console.log("Toggle Lock Response Data: ", data);
    return data;

  } catch (error) {
    console.error('Error toggling lock:', error);
    throw error; // Rethrow the error to handle it in the calling function
  }
}
  
const handleInternalNotesBlur = async () => {
  const updatedShipmentInfo = {
    notes: shipmentInfo.notes,
    internal_notes: internalNotes
  };

  let retry = false;

  try {
    // First attempt to update shipment info
    const response = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedShipmentInfo)
    });

    if (!response.ok) {
      throw new Error('Failed to update shipment info');
    }

    const data = await response.json();
    showSnackbar('Shipment info updated successfully', 'success');

    console.log("Shipment info updated successfully:", data);
  } catch (error) {
    console.error('Error updating shipment info, attempting to toggle lock:', error);
    
    // First toggle lock to unlock
    try {
      await toggleLock(shipmentId);
      retry = true;  // Set flag to retry
    } catch (lockError) {
      console.error('Error toggling lock:', lockError);
    }

    // If lock toggle was successful, retry the update
    if (retry) {
      try {
        const response = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedShipmentInfo)
        });

        if (!response.ok) {
          throw new Error('Failed to update shipment info');
        }

        const data = await response.json();
        showSnackbar('Shipment info updated successfully', 'success');

        console.log("Shipment info updated successfully after toggle lock:", data);
      } catch (retryError) {
        console.error('Error updating shipment info after toggle lock:', retryError);
      } finally {
        // Toggle lock again to return to original state
        try {
          await toggleLock(shipmentId);
        } catch (finalLockError) {
          console.error('Error toggling lock back:', finalLockError);
        }
      }
    }
  }
};


  const checkProgress = async () => {
    try {
      const response = await fetch(`${API_URL}/api/submit_box_progress`);
      const result = await response.json();
      setProgress(result.progress);
      if (result.progress >= 0 && result.progress < 100) {
        setTimeout(checkProgress, 1000);
      } else {
        setProgress(0);
        setShowProgressBar(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      setProgress(0);
      setShowProgressBar(false);
      setIsSubmitting(false);
      console.error('Error checking progress:', error);
    }
  };

  const fetchNicknames = async () => {
    try {
      const response = await fetch(`${API_URL}/api/nicknames`);
      const data = await response.json();
      setNicknames(data);
    } catch (error) {
      console.error('Error fetching nicknames:', error);
    }
  };

  useEffect(() => {
    fetchNicknames();
  }, []);

  const handleNicknameSelect = (nickname) => {
    setSelectedNickname(nickname);
    window.selectedNickname = nickname;
  };

  useEffect(() => {
    if (selectedNickname) {
      console.log('Fetching all data...');
    }
  }, [selectedNickname, shipmentId]);

  useEffect(() => {
  }, [boxes]);

  const findClient = (team_id, clients) => {
    const client = clients.find(client => client.id === team_id);
    if (!client) return { name: 'Unknown', country: 'Unknown', email: '' };

    const name = client.name;
    let country = 'Unknown';
    if (name.includes('(US)')) {
      country = 'USA';
    } else if (name.includes('(CA)')) {
      country = 'Canada';
    }

    return { name, country, email: client.email };
  };



  const generatePickingList = () => {
    const shelvedItems = shipmentItems.filter(item => 
      shelvingData.some(data => data.SKU === item.item.merchant_sku)
    );
  
    const sortedShelvedItems = shelvedItems.sort((a, b) => {
      const getShelvingCode = (sku) => shelvingData.find(data => data.SKU === sku).FULL_SHELF_CODE;
  
      const [aAisle, aRow, aShelf, aBin] = getShelvingCode(a.item.merchant_sku).split('-');
      const [bAisle, bRow, bShelf, bBin] = getShelvingCode(b.item.merchant_sku).split('-');
  
      if (aAisle !== bAisle) return aAisle.localeCompare(bAisle);
      if (aRow !== bRow) return parseInt(aRow) - parseInt(bRow);
      if (aShelf !== bShelf) return parseInt(aShelf) - parseInt(bShelf);
      if (aBin && bBin) return aBin.localeCompare(bBin);
      return 0;
    });
  
    const listData = sortedShelvedItems.map(item => {
      const shelvingCode = shelvingData.find(data => data.SKU === item.item.merchant_sku).FULL_SHELF_CODE;
      return {
        title: item.item.title,
        quantity: item.quantity,
        shelvingCode
      };
    });
  
    setPickingListData(listData);
    setShowPickingListModal(true);
  };

  const handleUpdateBoxDetails = async (updatedBox) => {
    if (updatedBox.items.length > 0) {
      try {
        const length = updatedBox.items[0].LENGTH;
        const width = updatedBox.items[0].WIDTH;
        const height = updatedBox.items[0].HEIGHT;
        const weight = updatedBox.items[0].WEIGHT;

        const response = await fetch(`${API_URL}/api/update_box_details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ shipmentId, selectedPlan, updatedBox, length, width, height, weight, selectedNickname }),  
        });

        if (!response.ok) {
          throw new Error('Failed to update box details');
        }

        await refreshBoxes();
      } catch (error) {
        console.error('Error updating box details:', error);
      }
    }

    setBoxes(prevBoxes => prevBoxes.map(box => box.boxNumber === updatedBox.boxNumber ? updatedBox : box));

    if (selectedBox && selectedBox.boxNumber === updatedBox.boxNumber) {
      setSelectedBox(updatedBox);
    }
  };
  const handleBoxClick = (box) => {
    setOpenBoxNumber(prevBoxNumber => prevBoxNumber === box.boxNumber ? null : box.boxNumber);
    setSelectedBox(prevBox => prevBox === box ? null : box);
  };
  

  const handleAddBox = async () => {
    //setSaving(true);
    const newBoxNumber = boxes.length + 1;
    const newBox = {
      boxNumber: newBoxNumber,
      items: [],
      LENGTH: null,
      WIDTH: null,
      HEIGHT: null,
      WEIGHT: null,
      OUTBOUND_ID: shipmentId,
      FBA_ID: selectedPlan.fba_shipment_id,
      employee: selectedNickname
    };
    
    try {
      const response = await fetch(`${API_URL}/api/add_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBox)
      });
  
      const result = await response.json();
      if (result.success) {
        refreshBoxes();
      } else {
        console.error('Error adding box:', result.error);
      }
    } catch (error) {
      console.error('Error adding box:', error);
    } finally {
      //setSaving(false);
    }
  };
  

  const filteredDbData = boxes.filter(box =>
    box.items.length === 0 ||
    box.items.some(item =>
      item.SKU.toLowerCase().includes(boxSearchTerm.toLowerCase()) ||
      item.ASIN.toLowerCase().includes(boxSearchTerm.toLowerCase()) ||
      item.FNSKU.toLowerCase().includes(boxSearchTerm.toLowerCase())
    )
  );

  const sortedDbData = [...filteredDbData].sort((a, b) => {
    if (boxSortOrder === 'asc') {
      return a.boxNumber - b.boxNumber;
    } else {
      return b.boxNumber - a.boxNumber;
    }
  });

  const handleDuplicateBox = async (box, times = 1) => {
    try {
      const response = await fetch(`${API_URL}/api/duplicate_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shipmentId,
          selectedPlan,
          box,
          maxBoxLength: boxes.length,
          times,
          selectedNickname
        })
      });
      console.log("Sending dupe info: ", {
        shipmentId,
        selectedPlan,
        box,
        maxBoxLength: boxes.length,
        times,
        selectedNickname
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to duplicate box: ${errorText}`);
      }

      await refreshBoxes();
    } catch (error) {
      console.error('Error duplicating box:', error);
    }
  };

  const calculateTotalQuantityInBoxes = (sku) => {
    if (!Array.isArray(boxes)) {
      return 0;
    }

    return boxes.reduce((acc, box) => {
      return acc + box.items.reduce((itemAcc, item) => {
        return itemAcc + (item.SKU === sku ? Number(item.QTY) : 0);
      }, 0);
    }, 0);
  };

  const getItemStatus = (item) => {
    const totalQuantity = Number(item.quantity);
    const quantityInBoxes = calculateTotalQuantityInBoxes(item.item.merchant_sku);

    if (quantityInBoxes === 0) {
      return 'Not Started';
    } else if (quantityInBoxes < totalQuantity) {
      return 'In Progress';
    } else if (quantityInBoxes === totalQuantity) {
      return 'Completed';
    } else if (quantityInBoxes > totalQuantity) {
      return 'Over Limit';
    }
  };

  const filterItemsByStatus = (items, status) => {
    if (status === 'All') return items;

    return items.filter(item => getItemStatus(item) === status);
  };

  const filterItemsByPrep = (items, prepFilter) => {
    if (!prepFilter) return items;

    return items.filter(item => item.item.prep_instructions.length > 1);
  };

  const filterItemsByShelved = (items, shelvedFilter) => {
    if (!shelvedFilter) return items;

    return items.filter(item => shelvingData.some(data => data.SKU === item.item.merchant_sku));
  };

  const filterItemsByPlan = (items, selectedPlan, showItemsForAllSplits) => {
    if (showItemsForAllSplits || !selectedPlan) return items;
    return items.filter(item => 
      selectedPlan.items.some(planItem => planItem.seller_sku === item.item.merchant_sku)
    );
  };
  useEffect(() => {
    console.log('Modal state changed:', showSendEmailModal);
  }, [showSendEmailModal]);

  useEffect(() => {
    const x = filterItemsByPlan(shipmentItems, selectedPlan, showItemsForAllSplits);
    if (x && boxes) {
      const totalUnitsValue = x.reduce((acc, item) => acc + item.quantity, 0);
      const unitsPackedValue = x.reduce((acc, item) => acc + calculateTotalQuantityInBoxes(item.item.merchant_sku), 0);
      const totalSKUs = x.length;
      const skusCompletedValue = x.filter(item => calculateTotalQuantityInBoxes(item.item.merchant_sku) >= item.quantity).length;
  
      setTotalUnits(totalUnitsValue);
      setUnitsPacked(unitsPackedValue);
      setSkusCompleted(skusCompletedValue);
  
      const unitProgressValue = totalUnitsValue > 0 ? (unitsPackedValue / totalUnitsValue) * 100 : 0;
      setUnitProgress(unitProgressValue);
  
      const skuProgressValue = totalSKUs > 0 ? (skusCompletedValue / totalSKUs) * 100 : 0;
      setSkuProgress(skuProgressValue);
    }
  }, [shipmentItems, boxes, selectedPlan]); // Dependencies
  



  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
  
    const flattenedData = [];
    boxes.forEach(box => {
      box.items.forEach(item => {
        flattenedData.push({
          BOX_NUMBER: box.boxNumber,
          TITLE: item.TITLE,
          SKU: item.SKU,
          QTY: item.QTY,
          LENGTH: item.LENGTH,
          WIDTH: item.WIDTH,
          HEIGHT: item.HEIGHT,
          WEIGHT: item.WEIGHT
        });
      });
    });
  
    const wsBoxes = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, wsBoxes, 'Box Contents');
  
    const shipmentItemsData = shipmentItems.map(item => ({
      TITLE: item.item.title,
      SKU: item.item.merchant_sku,
      ASIN: item.item.asin,
      FNSKU: item.item.fnsku,
      EXPIRY_DATE: item.expiry_date || '',
      SERVICES_ADDED: item.company_services
        .map(service => service.name)
        .join(', ')
    }));
  
    const wsShipmentItems = XLSX.utils.json_to_sheet(shipmentItemsData);
    XLSX.utils.book_append_sheet(wb, wsShipmentItems, 'Shipment Items');
  
    XLSX.writeFile(wb, `Export ${selectedPlan.fba_shipment_id}.xlsx`);
  };

  const handleSubmitBoxContents = async (confirmed = false, ignoreOversizedCheck = false) => {

    if (!ignoreOversizedCheck && !checkOversizedItemsWithoutFees()) {
      return; // Stop the submission if there are oversized items without fees
    }
    if (!confirmed) {
      setShowShelfReminder(true); // Show the shelving confirmation modal
      return; // Stop further processing until the user confirms
  }

    const transformedBoxes = [];
    let currentBox = null;
    let repeatCount = 1;

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      const transformedBox = {
        length_mm: parseInt(box.items[0].LENGTH) * 10,
        width_mm: parseInt(box.items[0].WIDTH) * 10,
        height_mm: parseInt(box.items[0].HEIGHT) * 10,
        weight_gm: parseInt(box.items[0].WEIGHT) * 1000,
        repeat: 1,
        items: box.items.map(item => {
          const matchingItem = shipmentItems.find(shipmentItem => shipmentItem.item.merchant_sku === item.SKU);
          const planItem = selectedPlan.items.find(planItem => planItem.seller_sku === item.SKU);
          return {
            item_id: matchingItem ? matchingItem.item.id : null,
            quantity: item.QTY,
            plan_item_id: planItem ? planItem.id : null,
            expiration: matchingItem ? matchingItem.expiry_date : null
          };
        })
      };

      if (currentBox && JSON.stringify(currentBox) === JSON.stringify(transformedBox)) {
        repeatCount++;
      } else {
        if (currentBox) {
          currentBox.repeat = repeatCount;
          transformedBoxes.push(currentBox);
        }
        currentBox = transformedBox;
        repeatCount = 1;
      }
    }

    if (currentBox) {
      currentBox.repeat = repeatCount;
      transformedBoxes.push(currentBox);
    }

    setShowProgressBar(true);
    setIsSubmitting(true);
    await fetch(`${API_URL}/api/begin_box_progress`);
    checkProgress();
    try {
      const response = await fetch(`${API_URL}/api/submit_box_contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ shipmentId, selectedPlan, boxes: transformedBoxes })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message);
      }

      window.open(`https://app.yyzprep.ca/staff/fba-transport/v0/plans/${selectedPlan.id}#box_contents`,'_blank');
    } catch (error) {
      console.error('Error submitting box contents:', error);
      setIsSubmitting(false);
      alert(`Error submitting box contents: ${error.message}`);
    }
  };

  if (!selectedNickname) {
    return <NicknameModal nicknames={nicknames} onSelect={handleNicknameSelect} />;
  }

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (!shipmentInfo || !shipmentItems) {
    return <div className="p-4">No data available</div>;
  }
  const statusCounts = {
    NotStarted: 0,
    InProgress: 0,
    Completed: 0,
    OverLimit: 0
  };

  const skuCount = shipmentItems.length;
  const itemCount = shipmentItems.reduce((total, item) => total + item.quantity, 0);

  let filteredShipmentItems = shipmentItems.filter(item =>
    item.item.merchant_sku.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.title.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.asin.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.fnsku.toLowerCase().includes(itemSearchTerm.toLowerCase())
  );

  filteredShipmentItems = filterItemsByStatus(filteredShipmentItems, statusFilter);
  filteredShipmentItems = filterItemsByPrep(filteredShipmentItems, prepFilter);
  filteredShipmentItems = filterItemsByShelved(filteredShipmentItems, shelvedFilter);
  filteredShipmentItems = filterItemsByPlan(filteredShipmentItems, selectedPlan, showItemsForAllSplits);


  filterItemsByPlan(filteredShipmentItems, selectedPlan, showItemsForAllSplits).forEach(item => {
    const status = getItemStatus(item);
    //console.log("Status of item: ", item, status);
    if (status === 'Not Started') statusCounts.NotStarted++;
    if (status === 'In Progress') statusCounts.InProgress++;
    if (status === 'Completed') statusCounts.Completed++;
    if (status === 'Over Limit') statusCounts.OverLimit++;
  });


  if (selectedPlan) {
    filteredShipmentItems = filteredShipmentItems.map(item => {
      const planItem = selectedPlan.items.find(planItem => planItem.seller_sku === item.item.merchant_sku);
      if (planItem) {
        console.log("It's a plan item....");
        console.log("Selected Plan ID is: ", selectedPlan.fba_shipment_id);
        console.log(planItem);
        return { ...item, quantity: planItem.quantity };
      }
      return item;
    });
  }
  const totalSKUs = shipmentItems.length;


  const groupedPlans = plans.reduce((acc, plan) => {
    const date = moment(plan.created_at).format('MMM D, h:mm:ss A'); 
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(plan);
    return acc;
  }, {});
  const planOptions = Object.keys(groupedPlans)
  .sort((a, b) => new Date(b) - new Date(a)) // Sort dates in descending order
  .map(date => ({
    label: date,
    options: groupedPlans[date]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(plan => ({
        value: plan.id,
        label: `${plan.fba_shipment_id} | ${plan.name} - ${plan.items.length} SKUs - ${plan.items.reduce((sum, item) => sum + item.quantity, 0)} Units${plan.is_rejected ? ' (Rejected)' : (plan.is_accepted ? ' (Accepted)' : '')}`
      }))
  }));

  
 
  const itemsPerPage = 15; // Adjust this number to the desired items per page
  const totalPages = Math.ceil(filteredShipmentItems.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredShipmentItems.slice(indexOfFirstItem, indexOfLastItem);


  const handleShowReassignModal = (mainOutboundId) => {
    setMainOutboundId(mainOutboundId);
    setShowReassignPlanModal(true);
  };

  return (

    <div className="p-4">
          <>
          <Snackbar 
  open={snackbarOpen} 
  autoHideDuration={1250} 
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Positioning at top-right
>
  <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
    {snackbarMessage}
  </Alert>
</Snackbar>

  </>
<header className="bg-blue-500 text-white p-6 rounded-t-lg shadow-md">
  <div className="flex justify-between items-start">
    <div className="flex-1">
      <h1 className="text-2xl font-bold mb-2">{shipmentInfo.name}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
        <div className="text-white">
          <p>Created: {new Date(shipmentInfo.created_at).toLocaleString()}</p>
          <p>
            Shipment ID:{" "}
            <a
              href={`https://app.yyzprep.ca/staff/shipments/outbound/${shipmentInfo.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-white"
            >
              {shipmentInfo.id}
            </a>
          </p>
          <p>SKU Count: {skuCount}</p>
          <p>Item Count: {itemCount}</p>
          {selectedPlan && (
            <>
              <p>
                FBA ID:{" "}
                <a
                  href={`https://app.yyzprep.ca/staff/fba-transport/v0/plans/${selectedPlan.id}#box_contents`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-white"
                >
                  {selectedPlan.fba_shipment_id}
                </a>
              </p>
              <p>
                PLAN ID:{" "}
                <a
                  href={`https://app.yyzprep.ca/staff/fba-transport/v0/plans/${selectedPlan.id}#box_contents`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-white"
                >
                  {selectedPlan.id}
                </a>
              </p>
            </>
          )}
        </div>

        <div>
          {clientInfo && (
            <>
              <p>Client: {clientInfo.name}</p>
              <p>Country: {clientInfo.country}</p>
            </>
          )}
          <p>Employee: {selectedNickname}</p>
          <span
            className={`px-2 py-1 rounded-full text-xs font-bold ${
              shipmentInfo.status.toLowerCase() === "shipped"
                ? "bg-green-500"
                : shipmentInfo.status.toLowerCase() === "open"
                ? "bg-red-500"
                : shipmentInfo.status.toLowerCase() === "planning" ||
                  shipmentInfo.status.toLowerCase() === "planned"
                ? "bg-orange-500"
                : shipmentInfo.status.toLowerCase() === "created"
                ? "bg-yellow-500"
                : ""
            }`}
          >
            Status: {shipmentInfo.status.toUpperCase()}
          </span>
          <div className="mt-4">
            <label className="block text-sm font-medium text-black">
              Select Plan:
            </label>
            <Select
              value={planOptions.find(
                (option) =>
                  option.value === (selectedPlan && selectedPlan.fba_shipment_id)
              )}
              onChange={handlePlanSelect}
              options={planOptions}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "black",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: "gray",
                  boxShadow: "none",
                }),
              }}
              className="mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
    
    <div className="flex flex-col">
      <div className="flex items-start space-x-4 mb-4">
        <button
          className="bg-green-500 text-white p-3 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 transition"
          onClick={exportToExcel}
        >
          Export to Excel
        </button>
        <label className="bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition cursor-pointer">
          Import Excel
          <input
            type="file"
            accept=".xlsx, .xls"
            className="hidden"
            ref={fileInputRef}
            onChange={handleImportExcel}
          />
        </label>
        <button
          className={`bg-purple-500 text-white p-3 rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300 transition ${
            isSubmitting ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-700 text-white"
          }`}
          onClick={handleSubmitBoxContents}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Box Contents"}
        </button>
        <button
          className="bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
          onClick={() => setShowSendEmailModal(true)}
        >
          Contact Client
        </button>
        <button
          className="bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
          onClick={generatePickingList}
        >
          Generate Picking List
        </button>

          <button
            className="bg-red-500 text-white p-3 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 transition"
            onClick={() => setShowResetDialog(true)}
          >
            Reset Box Contents
          </button>
      </div>

      {/* Notes Section Positioned Below Buttons */}
{/* Notes Section Positioned Below Buttons */}
<div className="grid grid-cols-2 gap-4">
  <textarea
    className="w-full h-24 p-2 border border-gray-300 rounded text-black"  // Added text-black to ensure text is visible
    placeholder="Client Notes"
    value={shipmentInfo.notes || ''}
    readOnly
  />
  <textarea
    className="w-full h-24 p-2 border border-gray-300 rounded text-black"  // Added text-black to ensure text is visible
    placeholder="Warehouse Notes"
    value={internalNotes || ''}
    onChange={handleInternalNotesChange}
    onBlur={handleInternalNotesBlur}
  />
</div>
{/* Confirmation Dialog */}
<Dialog open={showResetDialog} onClose={() => setShowResetDialog(false)}>
        <DialogTitle>Confirm Reset</DialogTitle>
        <DialogContent>
          Are you sure you want to reset all box contents? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleResetBoxContents}
            color="primary"
            style={{
              backgroundColor: isResetting ? 'gray' : '#ef4444', // Use a strong red color
              color: 'white',
            }}
            disabled={isResetting} // Disable button while resetting
            className="bg-red-500 text-white"
          >
            {isResetting ? "Resetting..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
</header>


<div className="bg-white p-4 rounded-b-lg shadow-md mb-4">
  <h3 className="text-lg font-semibold">Shipment Progress</h3>
  

<div className="mt-2">
  <span className="text-sm">Units Completed: {Math.round(unitProgress)}% ({unitsPacked}/{totalUnits})</span>
  <div className="relative pt-1">
    <LinearProgress 
      variant="determinate" 
      value={unitProgress} 
      style={{ height: '8px', borderRadius: '4px' }} // Adjust the height and borderRadius if needed
    />
  </div>
</div>


<div className="mt-2">
  <span className="text-sm">SKUs Completed: {Math.round(skuProgress)}%</span>
  <div className="relative pt-1">
    <LinearProgress 
      variant="determinate" 
      value={skuProgress} 
      style={{ height: '8px', borderRadius: '4px' }} // Adjust the height and borderRadius if needed
    />
  </div>
</div>
</div> 

      <main className="bg-white p-4 rounded-b-lg shadow-md">
        {/* <div className="flex mb-4">
          <textarea
            className="w-1/2 h-32 p-2 border border-gray-300 rounded"
            placeholder="Client Notes"
            value={shipmentInfo.notes}
            readOnly
          />
          <div className="flex flex-col justify-between mx-4">
            <button className="bg-blue-500 text-white p-2 rounded mb-2" onClick={generatePickingList}>Generate Picking List</button>

            <button onClick={() => setShowSendEmailModal(true)} className="bg-blue-500 text-white p-2 rounded mb-2">Contact Client</button>
          </div>
          <textarea
              className="w-1/2 h-32 p-2 border border-gray-300 rounded"
              placeholder="Warehouse Notes"
              value={internalNotes}
              onChange={handleInternalNotesChange}
              onBlur={handleInternalNotesBlur}
            />
        </div> */}
        {showProgressBar && (
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                  Box Contents Submitting...
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-blue-600">
                  {progress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 progress-bar"
              ></div>
              
            </div>
          </div>
        )}
      
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white shadow-md rounded-lg p-4">
            <div className="flex mb-4 space-x-4">
              <input
                type="text"
                placeholder="Search Outbound Items"
                value={itemSearchTerm}
                onChange={(e) => setItemSearchTerm(e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded"
              />
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="p-2 border border-gray-300 rounded"
              >
                <option value="All">All</option>
                <option value="Not Started">Not Started ({statusCounts.NotStarted}/{totalSKUs})</option>
                <option value="In Progress">In Progress ({statusCounts.InProgress}/{totalSKUs})</option>
                <option value="Completed">Completed ({statusCounts.Completed}/{totalSKUs})</option>
                <option value="Over Limit">Over Limit ({statusCounts.OverLimit}/{totalSKUs})</option>
              </select>
              <div>
                <label className="mr-2">Filters:</label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={prepFilter}
                    onChange={(e) => setPrepFilter(e.target.checked)}
                    className="form-checkbox"
                  />
                  <span className="ml-2">Items with Prep</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="checkbox"
                    checked={shelvedFilter}
                    onChange={(e) => setShelvedFilter(e.target.checked)}
                    className="form-checkbox"
                  />
                  <span className="ml-2">Shelved Items</span>
                </label>
                <label className="inline-flex items-center ml-4">
                  <input
                    type="checkbox"
                    checked={showItemsForAllSplits}
                    onChange={(e) => setShowItemsForAllSplits(e.target.checked)}
                    className="form-checkbox"
                  />
                  <span className="ml-2">Show Items for All Splits</span>
                </label>
              </div>
            </div>
<div className="pagination-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
  <IconButton
    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
    disabled={currentPage === 1}
    color="primary"
    aria-label="previous page"
  >
    <ArrowBack />
  </IconButton>
  
  <Typography variant="body1" style={{ margin: '0 20px' }}>
    Page {currentPage} of {totalPages}
  </Typography>
  
  <IconButton
    onClick={() => setCurrentPage(prev => (indexOfLastItem < filteredShipmentItems.length ? prev + 1 : prev))}
    disabled={currentPage >= totalPages}
    color="primary"
    aria-label="next page"
  >
    <ArrowForward />
  </IconButton>
</div>
            {currentItems.map((item, index) => (
              <OutboundItem
                key={index}
                item={item}
                selectedNickname={selectedNickname}
                quantityInBoxes={calculateTotalQuantityInBoxes(item.item.merchant_sku)}
                asinData={asinData}
                shelvingData={shelvingData.filter(data => data.SKU === item.item.merchant_sku)}
                updateShelvingQty={updateShelvingQty}
                removeShelving={removeShelving}
                servicesData={servicesData}
                clientsData={clientsData}
                country={clientInfo.country}
              />
            ))}
<div className="pagination-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
  <IconButton
    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
    disabled={currentPage === 1}
    color="primary"
    aria-label="previous page"
  >
    <ArrowBack />
  </IconButton>
  
  <Typography variant="body1" style={{ margin: '0 20px' }}>
    Page {currentPage} of {totalPages}
  </Typography>
  
  <IconButton
    onClick={() => setCurrentPage(prev => (indexOfLastItem < filteredShipmentItems.length ? prev + 1 : prev))}
    disabled={currentPage >= totalPages}
    color="primary"
    aria-label="next page"
  >
    <ArrowForward />
  </IconButton>
</div>

          </div>
          <div className="bg-white shadow-md rounded-lg p-4">
            <div className="flex justify-between mb-4">
              <button 
                className={`py-2 px-4 rounded ${!selectedPlan ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`} 
                onClick={handleAddBox}
                disabled={!selectedPlan}
              >
                Add Box
              </button>
              <button
                className="bg-gray-300 py-2 px-4 rounded"
                onClick={() => setBoxSortOrder(boxSortOrder === 'asc' ? 'desc' : 'asc')}
              >
                {boxSortOrder === 'asc' ? 'Sort Z-A' : 'Sort A-Z'}
              </button>
            </div>
            <input
              type="text"
              placeholder="Search Boxes"
              value={boxSearchTerm}
              onChange={(e) => setBoxSearchTerm(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            {filteredDbData.length === 0 &&   <p>{!selectedPlan ? "No Box Contents Available Without Plan" : "No boxes match your search criteria."}</p>
}
{sortedDbData.map((box, index) => (
  <Box
    key={box.boxNumber}
    boxNumber={box.boxNumber}
    isOpen={box.boxNumber === openBoxNumber} // Pass isOpen prop
    onClick={() => handleBoxClick(box)} // Pass onClick function
    country={clientInfo.country}
    shipmentItems={shipmentItems}
    asinData={asinData}
    selectedNickname={selectedNickname}
    selectedBox={selectedBox}
    onUpdateBoxDetails={handleUpdateBoxDetails}
    onDuplicateBox={handleDuplicateBox}
    shipmentId={shipmentId}
    fetchStandardBoxes={fetchStandardBoxes}
    standardBoxes={standardBoxes}
    clientCountry={clientInfo.country}
  />
))}

          </div>
        </div>
      </main>

      <Modal
  open={showShelfReminder}
  onClose={() => setShowShelfReminder(false)}
>
  <div className="flex items-center justify-center min-h-screen px-4">
    <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">Confirm Shelf Removal</h2>
      <p className="text-gray-700 mb-6 text-center">
        Please confirm that items have already been removed from the shelves before submitting the box contents.
      </p>
      <div className="flex justify-end space-x-3">
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={() => setShowShelfReminder(false)}
          style={{ fontSize: '0.875rem', padding: '8px 16px' }}
        >
          Go Back
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => {
            setShowShelfReminder(false);
            handleSubmitBoxContents(true, true);
          }}
          style={{ fontSize: '0.875rem', padding: '8px 16px' }}
        >
          Confirm
        </Button>
      </div>
    </div>
  </div>
</Modal>



      <Modal
  open={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  aria-labelledby="oversized-items-modal-title"
  aria-describedby="oversized-items-modal-description"
>
  <div className="flex items-center justify-center min-h-screen">
    <div className="bg-white rounded-lg shadow-lg w-full max-w-xl mx-4 p-8">
      <h2 id="oversized-items-modal-title" className="text-3xl font-bold mb-6 text-center">
        Oversized Items Without Fees
      </h2>
      <p id="oversized-items-modal-description" className="text-gray-700 mb-6 text-center text-lg">
        The following items look like they should be oversized but do not have fees applied. Are you sure they are not oversized?
      </p>
      <ul className="list-disc pl-6 mb-6 text-left text-gray-700 text-lg">
        {oversizedItemsWithoutFees.map((item, index) => (
          <li key={index} className="mb-3">
            <strong>{item.item.title}</strong> ({item.item.merchant_sku})
          </li>
        ))}
      </ul>
      <div className="flex justify-between mt-6">
        <Button 
          variant="contained"
          color="secondary"
          style={{ fontSize: '1rem', padding: '12px 24px' }}
          onClick={() => setIsModalOpen(false)}
        >
          Go Back and add fees
        </Button>
        <Button 
          variant="contained"
          color="primary"
          onClick={() => { 
            setIsModalOpen(false); 
            handleSubmitBoxContents(false, true); 
                    }} 
        >
          They are not oversized
        </Button>
      </div>
    </div>
  </div>
</Modal>


      {showSendEmailModal && (
        <SendEmail
          show={showSendEmailModal}
          recipientEmail={clientInfo.email}
          onClose={() => setShowSendEmailModal(false)}
        />
      )}
      {showImportModal && (
        <ImportModal
          data={importedData}
          onClose={() => setShowImportModal(false)}
          onConfirm={handleConfirmImport}
        />
      )}
      {showPickingListModal && (
        <PickingListModal
          show={showPickingListModal}
          onClose={() => setShowPickingListModal(false)}
          data={pickingListData}
        />
      )}
      {showReassignPlanModal && (
        <ReassignPlanModal
          show={showReassignPlanModal}
          shipmentId={shipmentId}
          onClose={() => setShowReassignPlanModal(false)}
          mainOutboundId={mainOutboundId}
          handleReassignPlan={handleReassignPlan}
        />
      )}
    </div>
    
  );
};

export default Shipment;
