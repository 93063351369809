import React from 'react';

const PickingListModal = ({ show, onClose, data }) => {
  const copyToClipboard = () => {
    const text = data.map(item => `Pick ${item.quantity}pcs from ${item.shelvingCode}: ${item.title}`).join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Picking list copied to clipboard!');
    }, () => {
      alert('Failed to copy picking list to clipboard.');
    });
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
        <div className="p-4 border-b">
          <h2 className="text-lg font-semibold">Picking List</h2>
        </div>
        <div className="p-4 max-h-64 overflow-y-auto">
          {data.map((item, index) => (
            <div key={index} className="mb-2">
              <strong>Pick {item.quantity}pcs from {item.shelvingCode}</strong>: {item.title}
            </div>
          ))}
        </div>
        <div className="p-4 border-t flex justify-end space-x-2">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={copyToClipboard}
          >
            Copy to Clipboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PickingListModal;
