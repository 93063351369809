import React, { useState, useEffect, useContext } from 'react';
import { TextField, Typography, Card, Button, Grid, IconButton, Drawer, Slide, CircularProgress, Tooltip, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import InboundContext from './InboundContext';
import { openDB } from 'idb';

const API_URL = process.env.REACT_APP_API_URL; // Ensure your environment variables are set

const StyledCard = styled(Card)({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition effect
  '&:hover': {
    transform: 'scale(1.05)', // Slightly scale the card on hover
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
    backgroundColor: '#f0f0f0', // Optional: change background color on hover
  },
});

const StyledGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'stretch',
});

// Splash screen styled component
const SplashScreen = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff', // You can adjust the background color or use an image
  zIndex: 9999, // Ensures the splash screen stays on top
  flexDirection: 'column',
});

function AddToShelf() {
  const [searchTerm, setSearchTerm] = useState('');
  const [allItems, setAllItems] = useState([]); // Store all items fetched from the API
  const [filteredResults, setFilteredResults] = useState([]); // Store filtered results based on search
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSplashVisible, setIsSplashVisible] = useState(false); // Splash screen state
  const [isFirstLoad, setIsFirstLoad] = useState(true); // Track if this is the first time loading
  const { shelvingData, loadShelvingData, selectedNickname, inboundInfo } = useContext(InboundContext);

  const [selectedItem, setSelectedItem] = useState(null); // State to manage selected item
  const [shelfCode, setShelfCode] = useState('');
  const [quantity, setQuantity] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lastReloadTime, setLastReloadTime] = useState(null); // Store the last reload timestamp

  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  useEffect(() => {
    fetchAllItems(); // Fetch all items on component mount
  }, []);

  useEffect(() => {
    if (!isLoading && searchTerm.length > 3) { // Only allow searching if data is loaded
      handleLocalSearch(); // Perform local search when the search term changes
    } else {
      setFilteredResults([]); // Clear results if search term is too short
    }
  }, [searchTerm, isLoading]);

  const fetchAllItems = async (forceReload = false) => {
    try {
      setIsSplashVisible(true); // Show splash screen
      const db = await openDB('ShelfDB', 1, {
        upgrade(db) {
          db.createObjectStore('items', { keyPath: 'ID' });
          db.createObjectStore('meta', { keyPath: 'key' });
        },
      });

      const cachedItems = await db.getAll('items');
      const cachedTimestamp = await db.get('meta', 'cachedTimestamp');

      const cacheDuration = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
      const isCacheValid = cachedItems.length > 0 && cachedTimestamp && (Date.now() - cachedTimestamp.value < cacheDuration);

      if (isCacheValid && !forceReload) {
        console.log('Using cached data from IndexedDB');
        setAllItems(cachedItems);
        setLastReloadTime(new Date(cachedTimestamp.value).toLocaleString());
        setIsLoading(false);
        setIsSplashVisible(false); // Hide splash screen
        setIsFirstLoad(false); // Mark that the first load is complete
        return;
      }

      if (isFirstLoad) {
        alert('First-time loading may take up to 2 minutes. Please click the "Load Data" button.');
        setIsFirstLoad(false);
      }

      const response = await fetch(`${API_URL}/api/search_items`);
      const data = await response.json();

      const transformedData = data.map(item => ({
        ID: item.ID,
        searchable: `${item.SKU} ${item.TITLE} ${item.ASIN} ${item.FNSKU} ${item.name}`.toLowerCase(),
        ...item,
      }));

      await db.clear('items');
      await db.put('meta', { key: 'cachedTimestamp', value: Date.now() });

      for (const item of transformedData) {
        await db.put('items', item);
      }

      setAllItems(transformedData); // Store the transformed data in state
      setLastReloadTime(new Date().toLocaleString());
      setIsLoading(false); // Set loading state to false once data is loaded
      setIsSplashVisible(false); // Hide splash screen
    } catch (error) {
      console.error('Error fetching items:', error);
      setIsLoading(false); // Ensure loading state is set to false even if there's an error
      setIsSplashVisible(false); // Hide splash screen in case of error
    }
  };

  const handleLocalSearch = () => {
    const keywords = searchTerm.toLowerCase().split(' ').filter(term => term.length > 0);

    const results = allItems.filter(item =>
      keywords.every(keyword => item.searchable.includes(keyword))
    );

    setFilteredResults(results);
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setDrawerOpen(true);
  };

  const handleAddToShelf = async () => {
    try {
      const response = await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: `${shelfCode}-${selectedItem.SKU}`, // Create a unique key
          qty: quantity,
          sku: selectedItem.SKU,
          shelfCode,
          employee: selectedNickname, // You can replace this with the appropriate value
          shipmentId: 'N/A', // Or replace with the correct shipment ID if available
          action_type: 'INBOUND_TOOL_SEARCHANDADDTOSHELF',
          team_id: selectedItem.TEAM_ID
        }),
      });
      const data = await response.json();
      if (data.success) {
        setSnackbarMessage(`Added ${quantity} of ${selectedItem.TITLE} to shelf ${shelfCode}`);
        setSnackbarOpen(true); // Show the snackbar with confirmation
        setDrawerOpen(false);
        setShelfCode('');
        setQuantity('');
      } else {
        alert('Error adding item to shelf');
      }
    } catch (error) {
      console.error('Error adding item to shelf:', error);
    }
  };

  return (
    <div>
      {isSplashVisible && ( // Show splash screen while loading fresh data
        <SplashScreen>
          <CircularProgress size={80} />
          <Typography variant="h5" style={{ marginTop: '16px' }}>
            Loading Data, Please Wait...
          </Typography>
        </SplashScreen>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Add to Shelf
        </Typography>
        <div>
          {lastReloadTime && (
            <Tooltip title={`Last Loaded: ${lastReloadTime}`}>
              <Typography variant="body2" color="textSecondary" style={{ marginRight: '8px' }}>
                Last Loaded: {lastReloadTime}
              </Typography>
            </Tooltip>
          )}
          <IconButton onClick={() => fetchAllItems(true)}>
            <RefreshIcon />
          </IconButton>
        </div>
      </div>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '16px' }}
        disabled={isLoading} // Disable search bar while loading
        placeholder={isLoading ? 'Loading items...' : 'Search items'}
      />

      {isLoading ? (
        // Show loading spinner while data is being fetched
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          {filteredResults.slice(0, 20).map((item) => (
            <StyledGrid item xs={12} sm={3} key={item.ID}> {/* 4-column grid */}
              <StyledCard onClick={() => handleCardClick(item)}>
                <img src={item.LARGE_IMG} alt={item.TITLE} style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '250px',
                  maxHeight: '250px',
                  objectFit: 'contain'
                }}></img>
                <div>
                  <Typography variant="body2">{item.TITLE}</Typography>
                  <Typography variant="caption">Company: {item.name}</Typography><br></br>
                  <Typography variant="caption">Qty in Stock: {item.QTY_IN_STOCK}</Typography><br></br>
                  <Typography variant="caption">{item.ASIN} | {item.FNSKU}</Typography><br></br>
                  <Typography variant="caption">{item.SKU}</Typography><br></br>
                  {item.INBOUND_ID && (
                    <Typography variant="caption">Inbound ID: {item.INBOUND_ID}</Typography>
                  )}
                </div>
              </StyledCard>
            </StyledGrid>
          ))}
        </Grid>
      )}

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        TransitionComponent={Slide}
        transitionDuration={0}
        PaperProps={{
          style: {
            transition: 'none',
            padding: '16px', // Reduced padding
            maxWidth: '600px',
            height: '100%', // Ensure the drawer takes the full height of the screen
            display: 'flex', // Flexbox layout to manage content overflow
            flexDirection: 'column',
          },
        }}
      >
        {selectedItem && (
          <div style={{ flexGrow: 1, overflowY: 'auto' }}> {/* Scrollable content area */}
            <div style={{ padding: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  {selectedItem.TITLE}
                </Typography>
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                <img
                  src={selectedItem.LARGE_IMG}
                  alt={selectedItem.TITLE}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '250px', // Reduced max width
                    maxHeight: '250px', // Reduced max height
                    objectFit: 'contain',
                    borderRadius: '8px',
                  }}
                />
              </div>

              <Typography variant="body1" style={{ marginBottom: '8px', fontWeight: 500 }}>
                Company: {selectedItem.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
                Qty in Stock: {selectedItem.QTY_IN_STOCK}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
                {selectedItem.ASIN} | {selectedItem.FNSKU}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
                {selectedItem.SKU}
              </Typography>
              {selectedItem.INBOUND_ID && (
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '16px' }}>
                  Inbound ID: {selectedItem.INBOUND_ID}
                </Typography>
              )}

              <TextField
                label="Shelf Code"
                variant="outlined"
                fullWidth
                value={shelfCode}
                onChange={(e) => setShelfCode(e.target.value.toUpperCase())}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                label="Quantity"
                variant="outlined"
                type="number"
                fullWidth
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                style={{ marginBottom: '24px' }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  padding: '12px',
                  fontSize: '16px',
                  fontWeight: 600,
                  backgroundColor: '#007BFF',
                }}
                onClick={handleAddToShelf}
              >
                Add to Shelf
              </Button>
            </div>
          </div>
        )}
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddToShelf;
