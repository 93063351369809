import React, { useContext } from 'react';
import Select from 'react-select';
import { BoxContext } from './BoxContext';

const CustomDropdown = ({ shipmentItems, onChange, value }) => {
  const { selectedPlan } = useContext(BoxContext);
  console.log(selectedPlan);
  
  const filteredShipmentItems = shipmentItems
    .filter(item => selectedPlan.items.some(planItem => planItem.seller_sku === item.item.merchant_sku))
    .map(item => {
      const planItem = selectedPlan.items.find(planItem => planItem.seller_sku === item.item.merchant_sku);
      return { ...item, quantity: planItem.quantity };
    });
  
  console.log(filteredShipmentItems);
  
  console.log("Filtered Dropdown Items", filteredShipmentItems  );
  const customStyles = {
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 400,
    }),
    menuList: (provided) => ({
      ...provided,
      minWidth: 400,
    }),
  };

  const formatOptionLabel = ({ item }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {item.images && item.images.length > 0 ? (
        <img src={item.images[0].large_url} alt={item.title} style={{ width: 50, height: 50, marginRight: 10 }} />
      ) : null}
      <div>
        <div style={{ fontSize: 14 }}>{item.title}</div>
        <div style={{ fontSize: 12, color: '#888' }}>{item.asin} / {item.fnsku} / {item.merchant_sku}</div>
      </div>
    </div>
  );

  const options = filteredShipmentItems.map((item) => ({
    value: item.item.asin,
    label: item.item.title,
    item: item.item,
  }));

  const filterOption = ({ label, value, data }, inputValue) => {
    // Convert inputValue to lowercase for case-insensitive comparison
    const searchTerm = inputValue.toLowerCase();
    
    // Check if the inputValue matches the title, fnsku, or merchant_sku
    return (
      label.toLowerCase().includes(searchTerm) ||
      data.item.fnsku.toLowerCase().includes(searchTerm) ||
      data.item.merchant_sku.toLowerCase().includes(searchTerm)
    );
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedOption) => onChange(selectedOption.item)}
      value={options.find(option => option.value === value) || null}
      placeholder="Select an option"
      filterOption={filterOption} // Apply custom filter
    />
  );
};

export default CustomDropdown;
