import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';

export const generatePDF = (item, quantity, expiryDate, printOption, separatorNumber) => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'in',
    format: [2.25, 1.25],
  });

  const canvas = document.createElement('canvas');
  canvas.width = 360; // Adjusted to fit the 2-inch width (2 inches * 180 dpi)
  canvas.height = 72; // Adjusted to fit the 0.4-inch height (0.4 inches * 180 dpi)
  const fnsku = item.item.identifiers.find(id => id.identifier_type === 'FNSKU').identifier;

  for (let i = 0; i < quantity; i++) {
    const condition = "New"; // Replace with actual condition if available

    // Generate the barcode on the canvas
    JsBarcode(canvas, fnsku, {
      format: "CODE128",
      displayValue: false,
      margin: 0,
    });

    const barcodeDataUrl = canvas.toDataURL('image/png');

    // Add barcode at the top
    doc.addImage(barcodeDataUrl, 'PNG', 0.125, 0.1, 2, 0.4);

    // Add FNSKU below the barcode, slightly lower to avoid overlap
    doc.setFontSize(12);
    doc.text(fnsku, 1.125, 0.7, { align: 'center' });

    // Prepare product name with middle truncation if needed
    let productName = item.item.title;
    if (productName.length > 80) {
      productName = `${productName.substring(0, 30)}...${productName.substring(productName.length - 30)}`;
    }

    // Add product name below the FNSKU
    doc.setFontSize(8);
    doc.text(productName, 0.125, 0.85, { maxWidth: 2 });

    // Add condition and expiry date at the bottom
    const expiryDateText = expiryDate ? `, expires ${expiryDate.toISOString().split('T')[0]}` : '';
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(`${condition}${expiryDateText}`, pageWidth - 0.125, 1.1, { align: 'right' });

    // Add the warning text if the "Warning Labels" print option is selected
       
    if (printOption === 'Ready to Ship') {
      doc.addPage();
    doc.setFontSize(16); 
      doc.text('READY TO SHIP', 1.125, 0.625, { align: 'center' });
    } else if (printOption === 'Sold as Set') {
      doc.addPage();
    doc.setFontSize(16); 
      doc.text('SOLD AS A SET', 1.125, 0.625, { align: 'center' });
      doc.setFontSize(12);
      doc.text('DO NOT SEPARATE', 1.125, 0.875, { align: 'center' });
    } else if (printOption === 'Warning Labels') {
      doc.addPage();
      doc.setFontSize(9);
      const warningText = `
        WARNING: To avoid danger of suffocation, keep plastic bags away from babies and children.  Do not use this bag in cribs, beds, carriages, or play pens. This bag is not a toy.
      `;
      doc.text(warningText, 0.125, 0.2, { maxWidth: 2 });
    }

    // Add a separator if needed
    if (separatorNumber && (i + 1) % separatorNumber === 0 && i < quantity - 1) {
      doc.addPage();
      doc.setFontSize(24);
      doc.text('---SEPARATOR---', pageWidth / 2, 0.625, { align: 'center' });
    }

    if (i < quantity - 1) {
      doc.addPage();
    }
  }

  doc.save(`${quantity} x ${fnsku}.pdf`);
  // Open the PDF in a new window
  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const printWindow = window.open(pdfUrl);
};
