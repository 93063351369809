import React, { useState } from 'react';
import CustomDropdown from './CustomDropdown';

const AddItem = ({ shipmentItems, onAddItem, boxNumber, selectedNickname, length, width, height, weight, shipmentId, selectedPlan }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [qty, setQty] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const handleAddItem = async () => {
    if (!selectedItem || !qty) {
      console.log('Please select an item and enter a quantity');
      return;
    }

    const newItem = {
      selectedPlan,
      shipmentId,
      boxNumber,
      sku: selectedItem.merchant_sku,
      asin: selectedItem.asin,
      fnsku: selectedItem.fnsku,
      qty,
      length,
      width,
      height,
      title: selectedItem.title,
      weight,
      pallet: selectedItem.pallet,
      employee: selectedNickname,
      images: selectedItem.images // Ensure this line is present
    };

    // Send the new item to the server
    try {
      const response = await fetch(`${API_URL}/api/add_item_to_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newItem),
      });

      const result = await response.json();
      if (result.success) {
        console.log('Item added successfully');
        // Call the parent onAddItem function to update the state
        onAddItem(newItem);

        // Clear the quantity field but keep the selected item
        // setQty('');
      } else {
        console.error('Error adding item:', result.error);
      }
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    setQty('1'); // Set quantity to 1 when an item is selected
  };

  return (
    <div className="mb-4">
      <label className="block mb-2">Add Item</label>
      <div className="flex space-x-2 items-center">
        <div className="flex-grow">
          <CustomDropdown
            shipmentItems={shipmentItems}
            onChange={handleDropdownChange}
            value={selectedItem ? selectedItem.asin : ''}
          />
        </div>
        <input
          type="number"
          className="w-20 p-2 border border-gray-300 rounded"
          placeholder="QTY"
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={handleAddItem}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddItem;
