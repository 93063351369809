import React, { useState, useContext, useEffect } from 'react';
import InboundContext from './InboundContext';
import moment from 'moment';

const SendEmailInbound = ({ recipientEmail, onClose, sendWithInboundInfo = true}) => {
  const { selectedNickname, inboundInfo } = useContext(InboundContext);

  // Define a default message when there is no inboundInfo
  const defaultMessage = `We have received items that we cannot locate an inbound for.
A brief description of the items/orders received is as follows:

Staff Comment:
Time of Comment: ${moment().format('ddd, MMM Do [at] h:mm A')}
Employee Name: ${selectedNickname}

Please provide any information requested, or process any adjustments or changes as needed ASAP and let us know.

Kind Regards,
YYZ Prep Team`;

  // If inboundInfo is available and sendWithInboundInfo is true, generate the usual message
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (inboundInfo && sendWithInboundInfo) {
      setMessage(`Staff Comment:

Time of Comment: ${moment().format('ddd, MMM Do [at] h:mm A')}
Employee Name: ${selectedNickname}
Inbound Name: ${inboundInfo.name}
Inbound Link: https://app.yyzprep.ca/shipments/inbound/${inboundInfo.id}

Please provide any information requested, or process any adjustments or changes as needed ASAP and let us know.

Send us an email, text, or call to notify us of any changes that may be necessary. Due to the volume of shipments being processed, and depending on your shipment type, it may be adjusted to ship out to improve lead times.

Kind Regards,
YYZ Prep Team`);
    } else {
      // Set default message if inboundInfo is not available or if sendWithInboundInfo is false
      setMessage(defaultMessage);
    }
  }, [inboundInfo, selectedNickname, sendWithInboundInfo]);

  const sendEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientEmail: recipientEmail,
          subject: `[ACTION REQUIRED] YYZ Prep | ${inboundInfo && sendWithInboundInfo ? 'Inbound Shipment Issue' : 'Unknown Inbound'}`,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Email sent successfully');
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div 
        className="bg-white rounded-lg shadow-lg p-6 w-full" 
        style={{ 
          maxWidth: '80vw',   // Adjust the width to 80% of the viewport width
          maxHeight: '90vh',  // Adjust the height to 90% of the viewport height
          overflowY: 'auto'   // Enable scrolling if the content is too tall
        }}
      >
        <h2 className="text-2xl font-semibold mb-6">Send Email to {recipientEmail}</h2>
        
        <label className="block mb-4 text-sm font-medium text-gray-700">
          Message
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-1 block w-full p-4 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            rows="15"  // Increased the number of rows for a larger textarea
            style={{ height: '600px' }}  // Set the fixed height of the textarea
          />
        </label>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={sendEmail}
            className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendEmailInbound;
