import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Shipment from './Shipment';
import USAHelper from './USAHelper/USAHelper';
import FoodFDACheck from './USAHelper/FoodFDACheck';
import PurchaseLabels from './USAHelper/PurchaseLabels';
import ValidateShipmentInfo from './USAHelper/ValidateShipmentInfo';
import { USAProvider } from './USAHelper/USAContext';

// Importing new billing tool components
import ClientOverview from './BillingTool/ClientOverview';
import ClientDetail from './BillingTool/ClientDetail';
import InvoiceGenerator from './BillingTool/InvoiceGenerator';
// Importing new inbound tool components
import InboundMain from './InboundTool/InboundMain';
import InboundDetail from './InboundTool/InboundDetail';
import InboundSearch from './InboundTool/InboundSearch';
import ShelfSearch from './InboundTool/ShelfSearch';
import AddToShelf from './InboundTool/AddToShelf';
import DeliveryLog from './InboundTool/DeliveryLog';
import ClientShelves from './InboundTool/ClientShelves';
import InboundHistory from './InboundTool/InboundHistory';
import InboundSidebar from './InboundTool/InboundSidebar';
import { InboundProvider } from './InboundTool/InboundContext';  // Import the InboundProvider
// Importing BillingContext
import OutboundSearch from './OutboundSearch';
import { BillingProvider } from './BillingTool/BillingContext';

function App() {
  return (
    <Router>
      <BillingProvider>
        <Routes>
          <Route path="/outbound/:shipmentId" element={<Shipment />} />
          <Route path="/outbound/search" element={<OutboundSearch />} />
          <Route path="/usa/:shipmentId" element={
            <USAProvider>
              <USAHelper />
            </USAProvider>
          } />
          <Route path="/usa/:shipmentId/fda" element={
            <USAProvider>
              <FoodFDACheck />
            </USAProvider>
          } />
          <Route path="/usa/:shipmentId/validateAddress" element={
            <USAProvider>
              <ValidateShipmentInfo />
            </USAProvider>
          } />
          <Route path="/usa/:shipmentId/purchaseLabels" element={
            <USAProvider>
              <PurchaseLabels />
            </USAProvider>
          } />

          {/* New Billing Tool Routes */}
          <Route path="/billing" element={<ClientOverview />} />
          <Route path="/billing/client/:teamId/:startDate/:endDate" element={<ClientDetail />} />
          <Route path="/billing/invoice/:id" element={<InvoiceGenerator />} />

         {/* New Inbound Tool Routes */}
         
         {/* New Inbound Tool Routes */}
         <Route path="/inbounds" element={
            <InboundProvider>
              <InboundSidebar>
                <InboundMain />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/:id" element={
            <InboundProvider>
              <InboundSidebar>
                <InboundDetail />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/search" element={
            <InboundProvider>
              <InboundSidebar>
                <InboundSearch />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/shelf-search" element={
            <InboundProvider>
              <InboundSidebar>
                <ShelfSearch />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/add-to-shelf" element={
            <InboundProvider>
              <InboundSidebar>
                <AddToShelf />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/delivery-log" element={
            <InboundProvider>
              <InboundSidebar>
                <DeliveryLog />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/client-shelves" element={
            <InboundProvider>
              <InboundSidebar>
                <ClientShelves />
              </InboundSidebar>
            </InboundProvider>
          } />
          <Route path="/inbounds/history" element={
            <InboundProvider>
              <InboundSidebar>
                <InboundHistory />
              </InboundSidebar>
            </InboundProvider>
          } />
        </Routes>
      </BillingProvider>
    </Router>
  );
}

export default App;
