import React, { useState, useEffect, useContext } from 'react';
import { TextField, Typography, Card, Button, Snackbar, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material';
import { styled } from '@mui/system';
import InboundContext from './InboundContext';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL; // Make sure to define this in your environment variables

const StyledCard = styled(Card)({
  display: 'flex',
  marginBottom: '16px',
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

function ShelfSearch() {
  const { shelvingData, loadShelvingData, selectedNickname } = useContext(InboundContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchableShelvingData, setSearchableShelvingData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editQtyArray, setEditQtyArray] = useState([]); // Array to manage quantities for each item
  const [initialQtyArray, setInitialQtyArray] = useState([]); // Array to store initial quantities
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [transferQty, setTransferQty] = useState(null);
  const [newShelfCode, setNewShelfCode] = useState('');
  const [transferType, setTransferType] = useState(''); // New state to manage transfer type
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [currentQty, setCurrentQty] = useState(0); // New state to manage current quantity for the transfer
  const [showTransferAlert, setShowTransferAlert] = useState(false); // New state to manage alert visibility
  const [transferDetails, setTransferDetails] = useState({ sku: null, teamId: null, shelfCode: null, qty: null }); // State to store transfer details

  useEffect(() => {
    if (shelvingData.length > 0) {
      const transformedData = shelvingData.map(item => {
        const { KEY, ...rest } = item;
        return {
          KEY,
          jsonData: JSON.stringify(rest)
        };
      });
      setSearchableShelvingData(transformedData);
    }
  }, [shelvingData]);

  useEffect(() => {
    loadShelvingData();
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const searchTerms = searchTerm.toLowerCase().split(' ').filter(term => term.length > 0);
  
      const results = searchableShelvingData
        .filter(item => {
          const jsonString = item.jsonData.toLowerCase();
          return searchTerms.every(term => jsonString.includes(term));
        })
        .slice(0, 20);
  
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm, searchableShelvingData]);

  // Initialize the editQtyArray and initialQtyArray when filteredResults change
  useEffect(() => {
    if (filteredResults.length > 0) {
      const initialQtyArray = filteredResults.map(item => JSON.parse(item.jsonData).QTY);
      setEditQtyArray(initialQtyArray); // Set the quantities for editing
      setInitialQtyArray(initialQtyArray); // Set the initial quantities
    }
  }, [filteredResults]);

  const handleEditQuantity = async (key, qty, shelfCode, sku) => {
    try {
      const response = await fetch(`${API_URL}/api/update_shelving_qty`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
          qty,
          employee: selectedNickname,
          sku,
          shelfCode,
          action_type: 'SHELF_SEARCH_UPDATE_QTY'
        }),
      });
      
      const data = await response.json();
      setSnackbarMessage(data.message);
      setSnackbarOpen(true);

      // Reload shelves and update search results
      await loadShelvingData();

      // Reset initial quantities to match the new data after update
      const updatedInitialQtyArray = [...initialQtyArray];
      const index = filteredResults.findIndex(item => JSON.parse(item.jsonData).KEY === key);
      if (index !== -1) {
        updatedInitialQtyArray[index] = qty;
        setInitialQtyArray(updatedInitialQtyArray);
      }

    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const handleTransfer = async (key, sku, teamId, shelfCode, currentQty, transferQty, transferType, newShelfCode) => {
    try {
      // If transfer quantity is greater than the current quantity, show alert
      if (transferQty > currentQty) {
        setShowTransferAlert(true);
        return; // Exit the function if the transfer quantity is invalid
      } else {
        setShowTransferAlert(false);
      }
  
      // Calculate the remaining quantity
      const remainingQty = currentQty - transferQty;
  
      // Handle the transfer based on the transfer type
      if (transferType === 'partialKeep') {
        // First, update the current shelf with the remaining quantity
        await fetch(`${API_URL}/api/update_shelving_qty`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key,
            qty: remainingQty, // Set the remaining quantity
            employee: selectedNickname,
            sku,
            shelfCode,
            action_type: 'SHELF_SEARCH_UPDATE',
            teamId, // Add teamId to the request
          }),
        });
  
        // Then, add the transferQty to the new shelf
        await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key: `${newShelfCode}-${sku}`, // Generate a new key for the new shelf
            qty: transferQty,
            sku,
            shelfCode: newShelfCode,
            employee: selectedNickname,
            shipmentId: 'N/A',
            action_type: 'SHELF_SEARCH_TRANSFER',
            teamId, // Add teamId to the request
          }),
        });
  
      } else if (transferType === 'partialRemove') {
        // Partial transfer and remove the remaining quantity
  
        // Update current shelf to 0 (remove all)
        await fetch(`${API_URL}/api/update_shelving_qty`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key,
            qty: 0, // Remove all remaining quantity
            employee: selectedNickname,
            sku,
            shelfCode,
            action_type: 'SHELF_SEARCH_REMOVE',
            teamId, // Add teamId to the request
          }),
        });
  
        // Add transferQty to the new shelf
        await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key: `${newShelfCode}-${sku}`, // Generate a new key for the new shelf
            qty: transferQty,
            sku,
            shelfCode: newShelfCode,
            employee: selectedNickname,
            shipmentId: 'N/A',
            action_type: 'SHELF_SEARCH_TRANSFER',
            teamId, // Add teamId to the request
          }),
        });
  
      } else {
        // Full transfer (transfer all items to the new shelf and remove from the current shelf)
  
        // Update current shelf to 0 (remove all)
        await fetch(`${API_URL}/api/update_shelving_qty`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key,
            qty: 0, // Remove all remaining quantity
            employee: selectedNickname,
            sku,
            shelfCode,
            action_type: 'SHELF_SEARCH_REMOVE',
            teamId, // Add teamId to the request
          }),
        });
  
        // Add transferQty to the new shelf (which is the entire currentQty)
        await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key: `${newShelfCode}-${sku}`, // Generate a new key for the new shelf
            qty: transferQty,
            sku,
            shelfCode: newShelfCode,
            employee: selectedNickname,
            shipmentId: 'N/A',
            action_type: 'transfer',
            teamId, // Add teamId to the request
          }),
        });
      }
  
      // Ensure the transfer dialog closes after the transfer is done
      setTransferDialogOpen(false);
  
      // Show a snackbar message to confirm the transfer
      setSnackbarMessage('Quantity transferred successfully.');
      setSnackbarOpen(true);
  
      // Reload shelves and update search results
      await loadShelvingData();
  
    } catch (error) {
      console.error('Error transferring quantity:', error);
    }
  };
  
  const handleRemove = async (key, shelfCode, sku) => {
    try {
      const response = await fetch(`${API_URL}/api/update_shelving_qty`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
          qty: 0,
          employee: selectedNickname,
          sku,
        shelfCode,
          action_type: 'SHELF_SEARCH_REMOVE_ALL'
        }),
      });

      const data = await response.json();
      setSnackbarMessage(data.message);
      setSnackbarOpen(true);
      setRemoveConfirmOpen(false);
      loadShelvingData(); // Reload shelves
    } catch (error) {
      console.error('Error removing quantity:', error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Shelf Search
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      {filteredResults.map((item, index) => {
        const jsonData = JSON.parse(item.jsonData);

        const hasUnsavedChanges = editQtyArray[index] !== initialQtyArray[index]; // Check if there are unsaved changes

        return (
          <StyledCard key={item.KEY}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={jsonData.LARGE_IMG} style={{ width: '150px', marginRight: '16px' }} />
              <div>
                <Typography variant="h6">{jsonData.TITLE}</Typography>
                <Typography variant="h6" sx={{ backgroundColor: 'yellow', display: 'inline' }}>
                  {jsonData.name}
                </Typography>
                <Typography variant="body2">ASIN: {jsonData.ASIN}</Typography>
                <Typography variant="body2">FNSKU: {jsonData.FNSKU}</Typography>
                <Typography variant="body2">SKU: {jsonData.SKU}</Typography>
                <Typography variant="h4" sx={{ backgroundColor: 'yellow', display: 'inline' }}>
                  Shelf: {jsonData.FULL_SHELF_CODE}
                </Typography><br />
                <Typography variant="h4" sx={{ backgroundColor: 'yellow', display: 'inline' }}>
                  Qty: {jsonData.QTY}
                </Typography>
                <Typography variant="body2">Employee: {jsonData.EMPLOYEE}</Typography>
                <Typography variant="body2">
                  Updated: {moment(jsonData.UPDATED).format("MMMM D [at] h:mm A")}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <TextField
                label="New Quantity"
                variant="outlined"
                type="number"
                value={editQtyArray[index]} // Bind the value from editQtyArray
                onChange={(e) => {
                  const newQtyArray = [...editQtyArray];
                  newQtyArray[index] = e.target.value;
                  setEditQtyArray(newQtyArray); // Update the specific item's quantity
                }}
                style={{ width: '100px' }}
              />
              {hasUnsavedChanges && (
                <Typography variant="body2" color="error">
                  *Unsaved changes
                </Typography>
              )}
<Button variant="contained" color="primary" onClick={() => handleEditQuantity(item.KEY, editQtyArray[index], jsonData.FULL_SHELF_CODE, jsonData.SKU)}>
Update Quantity
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                  setTransferDialogOpen(true); 
                  setCurrentKey(item.KEY); 
                  setCurrentQty(editQtyArray[index]); // Set the current quantity for the transfer dialog
                  setTransferQty(Number(editQtyArray[index])); // Prefill the transfer quantity
                  setNewShelfCode(jsonData.FULL_SHELF_CODE); // Set the shelf code
                  setTransferType(''); // Reset transfer type
                  // Store necessary values for transfer
                  setTransferDetails({
                    sku: jsonData.SKU,
                    teamId: jsonData.TEAM_ID,
                    shelfCode: jsonData.FULL_SHELF_CODE,
                    qty: jsonData.QTY,
                  });
                }}>
                Transfer
              </Button>
              <Button variant="contained" color="error" onClick={() => { setRemoveConfirmOpen(true); setCurrentKey(item.KEY); setCurrentQty(editQtyArray[index]); setNewShelfCode(jsonData.FULL_SHELF_CODE); }}>
              Remove
              </Button>
            </div>
          </StyledCard>
        );
      })}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {/* Transfer Dialog */}
      <Dialog open={transferDialogOpen} onClose={() => setTransferDialogOpen(false)}>
        <DialogTitle>Transfer Quantity</DialogTitle>
        <DialogContent>
          {showTransferAlert && (
            <Alert severity="warning">
              The transfer quantity is greater than the available quantity on the shelf. All available quantity will be transferred and removed from the current shelf.
            </Alert>
          )}
          <DialogContentText>
            Enter the quantity you want to transfer and the new shelf code.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Quantity to Transfer"
            type="number"
            fullWidth
            value={transferQty}
            onChange={(e) => setTransferQty(Number(e.target.value))}
          />
          <TextField
            margin="dense"
            label="New Shelf Code"
            type="text"
            fullWidth
            value={newShelfCode}
            onChange={(e) => setNewShelfCode(e.target.value)}
          />

          {/* Show transfer options only if transferQty is less than currentQty */}
          {Number(transferQty) < Number(currentQty) && (
            <RadioGroup value={transferType} onChange={(e) => setTransferType(e.target.value)}>
              <FormControlLabel value="partialKeep" control={<Radio />} label="Transfer and keep remaining" />
              <FormControlLabel value="partialRemove" control={<Radio />} label="Transfer and remove remaining" />
            </RadioGroup>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTransferDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button 
            color="primary" 
            onClick={() => handleTransfer(
              currentKey, 
              transferDetails.sku, 
              transferDetails.teamId, 
              transferDetails.shelfCode, 
              transferDetails.qty, 
              transferQty, 
              transferType,  // Ensure transferType is passed here
              newShelfCode
            )}
            disabled={transferType === '' && transferQty < currentQty} // Disable transfer button if transferType is not selected for partial transfer
          >
            Transfer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove Confirmation Dialog */}
      <Dialog open={removeConfirmOpen} onClose={() => setRemoveConfirmOpen(false)}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveConfirmOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleRemove(currentKey)} color="primary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShelfSearch;
