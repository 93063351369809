import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Button,
  Accordion,
  Spinner,
  Row,
  Col,
  Table,
  Form,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BillingContext } from './BillingContext';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://app.yyzprep.ca/api';
const API_TOKEN = 'Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq';

const ClientOverview = () => {
  const navigate = useNavigate();
  const { setSelectedClient, setStartDate, setEndDate, clients, setClients } =
    useContext(BillingContext);

  const [filteredClients, setFilteredClients] = useState([]);
  const [clientShipments, setClientShipments] = useState({});
  const [totalShipments, setTotalShipments] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalSKUs, setTotalSKUs] = useState(0);
  const [loading, setLoading] = useState(true);
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // January is 0!
    const currentDay = today.getDate();

    let startDate;
    let endDate;

    if (currentDay <= 10) {
      // First 10 days of the month: start and end of the previous month
      const previousMonth = new Date(currentYear, currentMonth - 1, 1);
      startDate = previousMonth;
      endDate = new Date(currentYear, currentMonth, 0); // Last day of the previous month
    } else if (currentDay >= new Date(currentYear, currentMonth + 1, 0).getDate() - 4) {
      // Last 5 days of the month: start and end of the current month
      startDate = new Date(currentYear, currentMonth, 1); // Start of this month
      endDate = new Date(currentYear, currentMonth + 1, 0); // Last day of this month
    } else {
      // Default behavior (you can adjust this as needed)
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(currentYear, currentMonth + 1, 0);
    }

    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const response = await fetch(
        `${API_BASE_URL}/merchants?api_token=${API_TOKEN}`
      );
      const data = await response.json();
      console.log('Clients received:', data.data);
      setClients(data.data);
      setLoading(false);
    };

    fetchClients();
  }, [setClients]);

  const fetchShipments = async (startDate, endDate) => {
    const startOfDay = new Date(startDate.setHours(0, 0, 1, 0));
    const endOfDay = new Date(endDate.setHours(23, 59, 59, 999));

    let shipments = [];
    let page = 1;
    let noResultPages = 0;

    // Limit pages to 10 for testing
    while (page <= 20) {
      const response = await fetch(
        `${API_BASE_URL}/shipments/outbound?page=${page}&api_token=${API_TOKEN}`
      );
      const data = await response.json();
      console.log('Shipments received:', data);

      const filteredShipments = data.data.filter((shipment) => {
    const shippedAt = new Date(shipment.shipped_at);
    const updatedAt = new Date(shipment.updated_at);
    if (shipment.status === 'closed' || shipment.status === 'shipped') {
        if (shippedAt >= startOfDay && shippedAt <= endOfDay) {
            return true;
        } else if (
            !shipment.shipped_at &&
            updatedAt >= startOfDay &&
            updatedAt <= endOfDay
        ) {
            return true;
        }
    }
    return false;
});

      console.log('Filtered shipments:', filteredShipments);

      shipments = [...shipments, ...filteredShipments];

      if (filteredShipments.length === 0) {
        noResultPages += 1;
        if (noResultPages >= 5) {
          break;
        }
      } else {
        noResultPages = 0;
      }

      page += 1;
    }

    console.log('Final Shipments received:', shipments);

    return shipments;
  };

  const processShipments = (shipments) => {
    const clientShipmentsMap = shipments.reduce((acc, shipment) => {
      const client = clients.find((client) => client.id === shipment.team_id);
      if (!client) return acc;
  
      const baseName = client.name.replace(/ \((CA|US)\)$/, '');
      const country = client.name.endsWith('(CA)') ? 'CA' : 'US';
  
      if (!acc[baseName]) {
        acc[baseName] = { CA: [], US: [] };
      }
  
      acc[baseName][country].push(shipment);
  
      return acc;
    }, {});
  
    console.log('Client Shipments Map:', clientShipmentsMap); // Debugging
  
    const filtered = clients.filter(
      (client) => clientShipmentsMap[client.name.replace(/ \((CA|US)\)$/, '')]
    );
  
    const groupedClients = filtered.reduce((acc, client) => {
      const baseName = client.name.replace(/ \((CA|US)\)$/, '');
      const country = client.name.endsWith('(CA)') ? 'CA' : 'US';
  
      if (!acc[baseName]) acc[baseName] = { CA: [], US: [] };
  
      acc[baseName][country].push(client);
  
      return acc;
    }, {});
  
    console.log('Grouped Clients:', groupedClients); // Debugging
  
    setFilteredClients(groupedClients);
    setClientShipments(clientShipmentsMap);
  
    const totalShipments = shipments.length;
    const totalUnits = shipments.reduce(
      (sum, shipment) => sum + (shipment.shipped_items_count || 0),
      0
    );
    const totalSKUs = shipments.reduce(
      (sum, shipment) => sum + (shipment.sku_count || 0),
      0
    );
    setTotalShipments(totalShipments);
    setTotalUnits(totalUnits);
    setTotalSKUs(totalSKUs);
  };
  


  const handleFilter = async () => {
    setLoading(true);
    setStartDate(localStartDate);
    setEndDate(localEndDate);

    const shipments = await fetchShipments(localStartDate, localEndDate);
    processShipments(shipments);
    setLoading(false);
  };

  const handleClientClick = (clientGroup) => {
    const teamIds = Object.values(clientGroup)
      .flat()
      .map(client => client.id)
      .join(',');
  
    const encodedStartDate = encodeURIComponent(localStartDate.toISOString());
    const encodedEndDate = encodeURIComponent(localEndDate.toISOString());
  
    const url = `/billing/client/${teamIds}/${encodedStartDate}/${encodedEndDate}`;
    window.open(url, '_blank');
  };
  
  

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Container style={{ paddingBottom: '150px' }}>
      <h4 className="my-4">Client Overview</h4>
      <Row className="mb-4">
        <Col>
          <h5>Total Shipments: {totalShipments}</h5>
          <h5>Total Units Shipped: {totalUnits}</h5>
          <h5>Total Unique SKUs: {totalSKUs}</h5>
        </Col>
      </Row>
      <Form>
        <Form.Group controlId="startDate">
          <Form.Label>Start Date</Form.Label>
          <DatePicker
            selected={localStartDate}
            onChange={(date) => setLocalStartDate(date)}
            className="form-control"
            dateFormat="yyyy/MM/dd"
          />
        </Form.Group>
        <Form.Group controlId="endDate" className="mt-3">
          <Form.Label>End Date</Form.Label>
          <DatePicker
            selected={localEndDate}
            onChange={(date) => setLocalEndDate(date)}
            className="form-control"
            dateFormat="yyyy/MM/dd"
          />
        </Form.Group>
        <Button variant="primary" className="mt-3" onClick={handleFilter}>
          Filter Clients
        </Button>
      </Form>
      <Accordion className="mt-4">
    {Object.keys(filteredClients).map((baseName) => (
      <Accordion.Item eventKey={baseName} key={baseName}>
        <Accordion.Header>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <span style={{ flexGrow: 1 }}>{baseName}</span>
            <Button
              variant="outline-primary"
              onClick={() => handleClientClick(filteredClients[baseName])}
            >
              Overview
            </Button>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {['CA', 'US'].map(
            (country) =>
              filteredClients[baseName] &&
              filteredClients[baseName][country] &&
              filteredClients[baseName][country].length > 0 && (
                <div key={country}>
                  <h5>{country} Clients</h5>
                  {filteredClients[baseName][country].map((client) => (
                    <Accordion key={client.id} className="mb-3">
                      <Accordion.Item eventKey={client.id.toString()}>
                        <Accordion.Header>
                          {client.name} -{' '}
                          {Array.isArray(
                            clientShipments[
                              client.name.replace(/ \((CA|US)\)$/, '')
                            ]
                          )
                            ? clientShipments[
                                client.name.replace(/ \((CA|US)\)$/, '')
                              ].length
                            : 0}{' '}
                          shipments
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Shipment ID</th>
                                <th>Updated At</th>
                                <th>Shipped At</th>
                                <th>Status</th>
                                <th>Units</th>
                                <th>SKUs</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(
                                clientShipments[
                                  client.name.replace(/ \((CA|US)\)$/, '')
                                ]
                              ) &&
                                clientShipments[
                                  client.name.replace(/ \((CA|US)\)$/, '')
                                ].map((shipment) => (
                                  <tr key={shipment.id}>
                                    <td>{shipment.id}</td>
                                    <td>{shipment.updated_at}</td>
                                    <td>{shipment.shipped_at}</td>
                                    <td>{shipment.status}</td>
                                    <td>{shipment.shipped_items_count || 0}</td>
                                    <td>{shipment.sku_count || 0}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </div>
              )
          )}
        </Accordion.Body>
      </Accordion.Item>
    ))}
  </Accordion>
    </Container>
  );
};

export default ClientOverview;
