import React, { createContext, useState, useEffect } from 'react';
import NicknameModal from './NicknameModal'; // Ensure you import the NicknameModal component

// Create the InboundContext
const InboundContext = createContext();

// Create a provider component
export const InboundProvider = ({ children }) => {
  const [selectedNickname, setSelectedNickname] = useState(null);
  const [inboundInfo, setInboundInfo] = useState(null);
  const [inboundItems, setInboundItems] = useState([]);
  const [shelvingData, setShelvingData] = useState([]);
  const [nicknames, setNicknames] = useState([]); // State to store nicknames
  const [clientsData, setClientsData] = useState([]); // State to store client data

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch shelving data
  const loadShelvingData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/get-shelving-data`);
      const data = await response.json();
      setShelvingData(data);
    } catch (error) {
      console.error('Failed to load shelving data:', error);
    }
  };

  // Fetch nicknames from the API
  const fetchNicknames = async () => {
    try {
      const response = await fetch(`${API_URL}/api/nicknames`);
      const data = await response.json();
      setNicknames(data);
    } catch (error) {
      console.error('Error fetching nicknames:', error);
    }
  };

  // Fetch clients data from the API
  const fetchClients = async () => {
    try {
      const response = await fetch(`${API_URL}/api/get_clients`);
      const data = await response.json();
      setClientsData(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  // Set inbound data (inboundInfo and inboundItems)
  const setInboundData = (data) => {
    if (data.inboundInfo) {
      setInboundInfo(data.inboundInfo);
    }
    if (data.inboundItems) {
      setInboundItems(data.inboundItems);
    }
  };

  // Load nicknames and clients when the component is mounted
  useEffect(() => {
    fetchNicknames();
    fetchClients(); // Fetch clients when component mounts
  }, []);

  // Handle nickname selection
  const handleNicknameSelection = (nickname) => {
    setSelectedNickname(nickname); // No need to save to localStorage
  };

  return (
    <InboundContext.Provider
      value={{
        inboundInfo,
        inboundItems,
        setInboundData,
        selectedNickname,
        shelvingData,
        loadShelvingData,
        setSelectedNickname: handleNicknameSelection, // Use this method for setting nickname
        nicknames,
        clientsData, // Provide clientsData to the context
      }}
    >
      {!selectedNickname ? (
        <NicknameModal
          nicknames={nicknames}
          onSelect={handleNicknameSelection}
        />
      ) : (
        children
      )}
    </InboundContext.Provider>
  );
};

export default InboundContext;
