import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid, Card, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment-timezone'; // Import moment-timezone

const API_URL = process.env.REACT_APP_API_URL;

// Styled components
const StyledCard = styled(Card)({
  padding: '16px',
  marginBottom: '16px',
  transition: 'transform 0.1s ease, box-shadow 0.1s ease',
  '&:hover': {
    transform: 'scale(1.01)',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
  },
});

const HistoryPage = () => {
  const [shelfLogs, setShelfLogs] = useState([]);
  const [inboundLogs, setInboundLogs] = useState([]);
  const [filteredShelfLogs, setFilteredShelfLogs] = useState([]);
  const [filteredInboundLogs, setFilteredInboundLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchLogs(); // Fetch all logs on component mount
  }, []);

  useEffect(() => {
    // Perform local search whenever the search term changes
    handleLocalSearch();
  }, [searchTerm, shelfLogs, inboundLogs]);

  const fetchLogs = async () => {
    setIsLoading(true);
    try {
      const shelfResponse = await fetch(`${API_URL}/api/shelf_logs`);
      const inboundResponse = await fetch(`${API_URL}/api/inbound_tool_log`);
      const shelfData = await shelfResponse.json();
      const inboundData = await inboundResponse.json();
      setShelfLogs(shelfData); // Store all logs in state
      setInboundLogs(inboundData);
      setFilteredShelfLogs(shelfData); // Initially show all logs
      setFilteredInboundLogs(inboundData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setIsLoading(false);
    }
  };

  const handleLocalSearch = () => {
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredShelf = shelfLogs.filter(log =>
        log.SKU.toLowerCase().includes(lowerCaseSearchTerm) ||
        log.EMPLOYEE.toLowerCase().includes(lowerCaseSearchTerm) ||
        log.FULL_SHELF_CODE.toLowerCase().includes(lowerCaseSearchTerm)
      );
      const filteredInbound = inboundLogs.filter(log =>
        log.SKU?.toLowerCase().includes(lowerCaseSearchTerm) ||
        log.EMPLOYEE.toLowerCase().includes(lowerCaseSearchTerm) ||
        log.INBOUND_ID.toString().includes(lowerCaseSearchTerm)
      );
      setFilteredShelfLogs(filteredShelf);
      setFilteredInboundLogs(filteredInbound);
    } else {
      // If no search term, show all logs
      setFilteredShelfLogs(shelfLogs);
      setFilteredInboundLogs(inboundLogs);
    }
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    return moment.tz(dateString, 'America/New_York').format('dddd, MMM D, YYYY [at] h:mm A');
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Inbound History
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '16px' }}
        placeholder="Search by SKU, Employee, or Inbound ID"
      />

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Shelf Logs Section */}
          <Typography variant="h5" gutterBottom>
            Shelf Logs
          </Typography>
          <Grid container spacing={2}>
            {filteredShelfLogs.length > 0 ? (
              filteredShelfLogs.map((log, index) => (
                <Grid item xs={12} key={index}>
                  <StyledCard>
                    <Typography variant="h6">Shelf Log</Typography>
                    <Typography variant="body2">Type: {log.TYPE}</Typography>
                    <Typography variant="body2">ID: {log.ID}</Typography>
                    <Typography variant="body2">SKU: {log.SKU}</Typography>
                    <Typography variant="body2">Quantity: {log.QTY}</Typography>
                    <Typography variant="body2">Shelf Code: {log.FULL_SHELF_CODE}</Typography>
                    <Typography variant="body2">Employee: {log.EMPLOYEE}</Typography>
                    <Typography variant="body2">
                      Updated: {formatDate(log.UPDATED)}
                    </Typography>
                  </StyledCard>
                </Grid>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" style={{ marginLeft: '16px' }}>
                No shelf logs found.
              </Typography>
            )}
          </Grid>

          {/* Inbound Tool Logs Section */}
          <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
            Inbound Tool Logs
          </Typography>
          <Grid container spacing={2}>
            {filteredInboundLogs.length > 0 ? (
              filteredInboundLogs.map((log, index) => (
                <Grid item xs={12} key={index}>
                  <StyledCard>
                    <Typography variant="h6">Inbound Tool Log</Typography>
                    <Typography variant="body2">Employee: {log.EMPLOYEE}</Typography>
                    <Typography variant="body2">Inbound ID: {log.INBOUND_ID}</Typography>
                    <Typography variant="body2">Type: {log.TYPE}</Typography>
                    <Typography variant="body2">SKU: {log.SKU}</Typography>
                    <Typography variant="body2">Quantity: {log.QTY}</Typography>
                    <Typography variant="body2">
                      Updated: {formatDate(log.UPDATED)}
                    </Typography>
                  </StyledCard>
                </Grid>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" style={{ marginLeft: '16px' }}>
                No inbound tool logs found.
              </Typography>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default HistoryPage;
