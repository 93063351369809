import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BoxContext } from './BoxContext';

const API_URL = process.env.REACT_APP_API_URL;

const BoxItemList = ({ boxNumber, shipmentItems, asinData, handleItemChange, handleItemBlur, handleDateChange, onRemoveItem, country }) => {
  const { boxes, setBoxes, reloadAsinData } = useContext(BoxContext);
  const box = boxes.find(x => x.boxNumber === boxNumber);

  const getImageUrl = (asin) => {
    const matchedItem = shipmentItems.find(item => item.item.asin === asin);
    return (matchedItem && matchedItem.item.images && matchedItem.item.images.length > 0 && matchedItem.item.images[0].large_url) 
      || 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq';
  };

  const getAsinData = (asin) => {
    return asinData.find(data => data.ASIN === asin) || {};
  };




  const getExpiryDate = (asin) => {
    const matchedShipmentItem = shipmentItems.find(item => item.item.asin === asin);
    return matchedShipmentItem ? matchedShipmentItem.expiry_date : null;
  };

  const updatePrice = async (asin, value) => {
    try {
      const response = await fetch(`${API_URL}/api/update_price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin, value })
      });
      const data = await response.json();
      console.log('Price updated:', data);
      reloadAsinData();
    } catch (error) {
      console.error('Error updating price:', error);
    }
  };

  const updateWeight = async (asin, weight) => {
    try {
      const response = await fetch(`${API_URL}/api/update_weight`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin, weight })
      });
      const data = await response.json();
      console.log('Weight updated:', data);
      reloadAsinData();
    } catch (error) {
      console.error('Error updating weight:', error);
    }
  };

  const handleQuantityChange = (e, index) => {
    const newQty = Number(e.target.value);
    const updatedBoxes = boxes.map(b => {
      if (b.boxNumber === boxNumber) {
        return {
          ...b,
          items: b.items.map((item, idx) => {
            if (idx === index) {
              return { ...item, QTY: newQty };
            }
            return item;
          })
        };
      }
      return b;
    });

    setBoxes(updatedBoxes);

    if (newQty === 0) {
      onRemoveItem(index);
    }
  };

  const handleItemFieldChange = async (e, field, index) => {
    const value = e.target.value;
    const updatedBoxes = boxes.map(b => {
      if (b.boxNumber === boxNumber) {
        return {
          ...b,
          items: b.items.map((item, idx) => {
            if (idx === index) {
              const asinDataItem = getAsinData(item.ASIN);
              if (field === 'unitPrice') {
                asinDataItem.VALUE = value;
              } else if (field === 'unitWeight') {
                asinDataItem.WEIGHT = value;
              }
              return { ...item };
            }
            return item;
          })
        };
      }
      return b;
    });

    setBoxes(updatedBoxes);

    const asin = box.items[index].ASIN;
    if (field === 'unitPrice') {
      await updatePrice(asin, value);
    } else if (field === 'unitWeight') {
      await updateWeight(asin, value);
    }

    handleItemChange(e, field, index);
  };

  const handleBlur = (index) => {
    handleItemChange({ target: { value: box.items[index].QTY } }, 'QTY', index);
    handleItemBlur(index);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div>
      {box.items.map((item, index) => {
        const asinDataItem = getAsinData(item.ASIN);
        const expiryDate = getExpiryDate(item.ASIN) || item.expiry;

        return (
          <div key={index} className="flex flex-col mb-4 border rounded-lg shadow-sm">
            <div className="flex items-start p-4">
              <img src={getImageUrl(item.ASIN)} alt={item.TITLE} className="w-16 h-16 object-contain rounded-lg mr-4" />
              <div className="flex-1">
                <p className="font-semibold">{item.TITLE}</p>
                <div className="text-sm text-gray-600 mt-1 space-y-1">
                  <p>{item.ASIN}</p>
                  <p>{item.FNSKU}</p>
                  <p>{item.SKU}</p>
                </div>
              </div>
              <button
                className="ml-auto text-red-500"
                onClick={() => onRemoveItem(index)}
              >
                &times;
              </button>
            </div>
            <div className="flex justify-between items-center p-4 bg-gray-100 rounded-b-lg">
              <div className="flex items-center">
                <span className="text-sm mr-2">Qty</span>
                <input
                  type="number"
                  value={item.QTY}
                  onChange={(e) => handleQuantityChange(e, index)}
                  onBlur={() => handleBlur(index)}
                  onFocus={handleFocus}
                  className="w-20 p-2 border border-gray-300 rounded"
                  placeholder="Qty"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="number"
                  step="0.0001"
                  value={asinDataItem.WEIGHT || ''}
                  onChange={(e) => handleItemFieldChange(e, 'unitWeight', index)}
                  onBlur={() => handleBlur(index)}
                  onFocus={handleFocus}
                  className="w-24 p-2 border border-gray-300 rounded"
                  placeholder="Weight"
                />
                <span className="text-sm ml-2">kg</span>
              </div>
              {country === 'USA' && (
                <div className="flex items-center">
                  <span className="text-sm mr-2">$</span>
                  <input
                    type="number"
                    value={asinDataItem.VALUE || ''}
                    onChange={(e) => handleItemFieldChange(e, 'unitPrice', index)}
                    onBlur={() => handleBlur(index)}
                    onFocus={handleFocus}
                    className="w-20 p-2 border border-gray-300 rounded"
                    placeholder="Price"
                  />
                </div>
              )}
              <div className="flex items-center">
                <DatePicker
                  selected={expiryDate ? new Date(expiryDate) : null}
                  onChange={(date) => handleDateChange(date, index)}
                  className="w-32 p-2 border border-gray-300 rounded"
                  placeholderText="Expiry"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BoxItemList;
