import React, { useContext, useState, useEffect } from 'react';
import BoxDetails from './BoxDetails';
import { BoxContext } from './BoxContext';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;

const Box = ({ boxNumber, isOpen, onClick, selectedBox, onUpdateBoxDetails, onDuplicateBox, setShipmentItems }) => {
  const [duplicateTimes, setDuplicateTimes] = useState(1);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { boxes, refreshBoxes, shipmentItems, asinData, clientInfo, selectedPlan, selectedNickname } = useContext(BoxContext);
  const box = boxes.find(x => x.boxNumber === boxNumber);
  const { shipmentId } = useParams();

  const [openBoxNumber, setOpenBoxNumber] = useState(null); // Track the currently open box
  const handleToggle = () => {
    // Use onClick prop to manage the open state externally
    onClick(box);
  };

  const handleThumbnailClick = (e) => {
    e.stopPropagation();
    handleToggle();
  };

  const handleDeleteBox = async () => {
    try {
      const response = await fetch(`${API_URL}/api/delete_box`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fba_shipment_id: selectedPlan.fba_shipment_id,
          boxNumber: boxNumber,
          employee: selectedNickname
        }),
      });
  
      if (response.ok) {
        console.log('Box deleted successfully');
        await refreshBoxes();
      } else {
        console.error('Failed to delete box');
        alert('Failed to delete box');
      }
    } catch (error) {
      console.error('Error deleting box:', error);
      alert('Error deleting box');
    }
  
    setShowDeleteConfirmation(false);
  };

  const getImageUrl = (asin) => {
    const matchedItem = shipmentItems.find(item => item.item.asin === asin);
    return (matchedItem && matchedItem.item.images && matchedItem.item.images.length > 0 && matchedItem.item.images[0].large_url) 
      || 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq';
  };
  
  const getQuantity = (asin) => {
    return box.items.reduce((acc, item) => item.ASIN === asin ? acc + Number(item.QTY) : acc, 0);
  };

  const getTotalValue = () => {
    return box.items.reduce((acc, item) => {
      const asinDataItem = asinData.find(data => data.ASIN === item.ASIN);
      if (asinDataItem && asinDataItem.VALUE) {
        return acc + (asinDataItem.VALUE * item.QTY);
      }
      return acc;
    }, 0);
  };

  const hasMissingValues = () => {
    return box.items.some(item => {
      const asinDataItem = asinData.find(data => data.ASIN === item.ASIN);
      return !asinDataItem || !asinDataItem.VALUE;
    });
  };

  const normalizedBoxNumber = String(boxNumber).trim().toLowerCase();

  const totalValue = getTotalValue();
  const formattedTotalValue = totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const valueStatusClass = hasMissingValues() ? 'bg-orange-500' : (totalValue > 800 ? 'bg-red-500' : 'bg-green-500');
  const valueStatusText = hasMissingValues() ? 'Some items are missing values!' : (totalValue > 800 ? 'Value exceeds $800!' : '');

  // Takes the first item and gets the dimensions and weight from it as the box dimensions are logged in each item row the same
  const formattedLength = box.length || null;
  const formattedWidth = box.width || null;
  const formattedHeight = box.height || null;
  const formattedWeight = box.weight || null;

  // Effect to trigger toggle if any dimension or weight is null
  useEffect(() => {
    if (formattedLength === null || formattedWidth === null || formattedHeight === null || formattedWeight === null) {
      //handleToggle();
      //onClick(box);

    }
  }, [formattedLength, formattedWidth, formattedHeight, formattedWeight]);

  return (
    <div className="border border-gray-300 rounded-lg shadow-md mb-4 relative">
      <div className="bg-gray-100 p-2 flex justify-between items-center rounded-t-lg" onClick={handleToggle}>
        <div className="flex items-center space-x-4">
          <div className="text-lg font-bold">Box #{boxNumber}</div>
          <div className="flex items-center space-x-2 text-sm">
            <div>{formattedLength} x {formattedWidth} x {formattedHeight} cm</div>
            <div>•</div>
            <div>{formattedWeight} kg</div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="text-sm">{box.items.length} SKU • {box.items.reduce((acc, item) => acc + item.QTY, 0)} Units</div>
          <div className="text-lg font-bold">{formattedTotalValue}</div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex flex-wrap gap-4 mt-4">
          {box.items && [...new Set(box.items.map(item => item.ASIN))].map((asin, index) => (
            <div key={index} className="relative w-20 h-20 bg-gray-100 rounded-lg overflow-hidden cursor-pointer hover:shadow-lg" onClick={handleThumbnailClick}>
              <img 
                src={getImageUrl(asin)} 
                alt={asin} 
                className="w-full h-full object-contain" 
              />
              <span className="absolute bottom-1 right-1 bg-red-500 text-white w-6 h-6 flex items-center justify-center rounded-full text-center text-sm font-bold">{getQuantity(asin)}</span>
            </div>
          ))}
        </div>
        {isOpen && (
          <BoxDetails
            boxNumber={boxNumber}
            selectedNickname={box.nickname}
            shipmentItems={shipmentItems}
            asinData={asinData}
            selectedBox={selectedBox}
            country={clientInfo.country}
            isOpen={isOpen}
            onAddItem={(newItem) => {
              const updatedBox = { ...selectedBox, items: [...selectedBox.items, newItem] };
              onUpdateBoxDetails(updatedBox);
              const updatedShipmentItems = shipmentItems.map(item => {
                if (item.item.asin === newItem.asin) {
                  return {
                    ...item,
                    quantity: item.quantity + newItem.qty
                  };
                }
                return item;
              });
              setShipmentItems(updatedShipmentItems);
            }}
            onUpdateBoxDetails={(updatedBox) => {
              onUpdateBoxDetails(updatedBox);
            }}
            shipmentId={shipmentId}
            refreshDbData={refreshBoxes}
            clientCountry={clientInfo.country}
          />
        )}
      </div>
      <div className="bg-gray-100 p-2 flex justify-between items-center rounded-b-lg">
        <div className="text-red-500 cursor-pointer" onClick={() => setShowDeleteConfirmation(true)}>Delete Box</div>
        <div className="flex space-x-2">
          <input
            type="number"
            value={duplicateTimes}
            onChange={(e) => setDuplicateTimes(Number(e.target.value))}
            className="w-16 p-2 border border-gray-300 rounded"
            min="1"
          />
          <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => onDuplicateBox(box, duplicateTimes)}>Duplicate Box</button>
        </div>
      </div>
      {showDeleteConfirmation && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <p>Are you sure you want to delete this box?</p>
            <div className="flex justify-end mt-4 space-x-2">
              <button className="bg-gray-300 py-2 px-4 rounded" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
              <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={handleDeleteBox}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Box;
