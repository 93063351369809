import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Divider, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MailIcon from '@mui/icons-material/Mail';
import InboundContext from './InboundContext';  // Import the context
import SendEmailInbound from './SendEmailInbound'; // Import the SendEmailInbound component
import Select from 'react-select';  // Import react-select

const drawerWidth = 170;

function InboundSidebar({ children }) {
  const { clientsData } = useContext(InboundContext); // Get clients from context
  const [openEmailModal, setOpenEmailModal] = useState(false); // Modal state for email
  const [selectedClient, setSelectedClient] = useState(null); // State to hold selected client

  const handleOpenEmailModal = () => {
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };

  // Map client data to options compatible with react-select
  const clientOptions = clientsData.map(client => ({
    value: client.email,
    label: client.name,
  }));

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem button component={RouterLink} to="/inbounds">
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Inbounds" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/search">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Search Inbounds" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/add-to-shelf">
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add to Shelf" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/shelf-search">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Shelf Search" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/delivery-log">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Delivery Log" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/client-shelves">
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Client Shelves" />
          </ListItem>
          <ListItem button component={RouterLink} to="/inbounds/history">
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Inbound History" />
          </ListItem>
        </List>

        {/* Contact Client Button */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<MailIcon />}
          sx={{ margin: '16px' }}
          onClick={handleOpenEmailModal}
        >
          Contact Client
        </Button>
      </Drawer>

      <main style={{ flexGrow: 1, padding: '16px' }}>
        {children}
      </main>

      {/* Email Modal */}
      <Dialog open={openEmailModal} onClose={handleCloseEmailModal} fullWidth maxWidth="sm">
        <DialogTitle>Select Client to Contact</DialogTitle>
        <DialogContent>
          <Select
            options={clientOptions}
            value={clientOptions.find(option => option.value === selectedClient)}
            onChange={(selectedOption) => setSelectedClient(selectedOption.value)}
            isSearchable
            placeholder="Select a client"
            menuPortalTarget={document.body}  // This allows the dropdown to render outside the modal
            styles={{ 
              menuPortal: base => ({ ...base, zIndex: 9999 })  // Ensure it appears above everything else
            }}
          />

          {selectedClient && (
            // Pass sendWithInboundInfo={false} when invoked from the sidebar
            <SendEmailInbound 
              recipientEmail={selectedClient} 
              onClose={handleCloseEmailModal} 
              sendWithInboundInfo={false} 
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InboundSidebar;
