import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingOverlay = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <Spinner animation="border" variant="primary" />
  </div>
);

export default LoadingOverlay;