import React, { useState, useEffect } from 'react';
import { Container, TextField, Card, CardContent, Typography, Grid, Box, Link, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const OutboundSearch = () => {
  const [outbounds, setOutbounds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    const fetchOutbounds = async () => {
      if (searchTerm.length > 2) {
        setLoading(true);
        setDebouncing(false);
        try {
          const response = await fetch(`${API_URL}/api/get_outbounds?searchTerm=${encodeURIComponent(searchTerm)}`);
          const data = await response.json();
          // Sort by UPDATED_AT in descending order (Z-A)
          const sortedData = data.sort((a, b) => new Date(b.UPDATED_AT) - new Date(a.UPDATED_AT));
          setOutbounds(sortedData);
        } catch (error) {
          console.error('Error fetching outbounds:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setOutbounds([]);
        setLoading(false);
      }
    };

    const debouncedFetch = debounce(() => {
      setDebouncing(false);  // Debouncing is done, start fetching
      fetchOutbounds();
    }, 700); // Debounce by 1 second

    setDebouncing(true);
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container>
      <Box my={4}>
        <TextField
          fullWidth
          label="Search internal notes or client notes"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Box>

      {loading || debouncing ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {outbounds.length === 0 && searchTerm.length > 2 ? (
            <Box my={4} display="flex" justifyContent="center">
              <Typography variant="h6" color="textSecondary">
                No results found for "{searchTerm}" in any notes.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {outbounds.map((outbound) => (
                <Grid item xs={12} sm={6} key={outbound.ID}>
                  <Card>
                    <CardContent>
                      <Link
                        href={`https://app.yyzprep.ca/staff/shipments/outbound/${outbound.ID}`}
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="h6" component="h2" gutterBottom>
                          {outbound.NAME}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Created: {new Date(outbound.CREATED_AT).toLocaleDateString()} | Updated: {new Date(outbound.UPDATED_AT).toLocaleDateString()}
                        </Typography>
                      </Link>
                      <Typography variant="body2" color="textSecondary">
                        ID: {outbound.ID}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Internal Notes: {outbound.INTERNAL_NOTES}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Client Notes: {outbound.CLIENT_NOTES}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Container>
  );
};

export default OutboundSearch;
