import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { USAContext } from "./USAContext";
import { saveAs } from "file-saver";
import { PDFDocument, rgb } from "pdf-lib";

const API_URL = process.env.REACT_APP_API_URL;
const useMockData = false; // Set this to true to use mock data for testing

const PurchaseLabels = () => {
  const { shipmentInfo, clientsData, boxes, asinData, selectedPlan } =
    useContext(USAContext);
  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [stallionPdfBytes, setStallionPdfBytes] = useState(null);
  const [upsPdfUrl, setUpsPdfUrl] = useState(""); // Add this to store UPS PDF URL

  const location = useLocation();
  const { address, city, state, zip } = location.state || {};  // Get address info from location state

  const navigate = useNavigate();

  useEffect(() => {
    if (shipmentInfo && clientsData) {
      const client = clientsData.find(
        (client) => client.id === shipmentInfo.team_id
      );
      console.log("Client found:", client);
      if (client) {
        fetchApiKey(client.name);
      } else {
        console.log("No client found for team_id:", shipmentInfo.team_id);
      }
    } else {
      console.log("shipmentInfo or clientsData not available yet");
    }
  }, [shipmentInfo, clientsData]);

  useEffect(() => {
    if (boxes) {
      calculateRequiredCredits(boxes);
    }
  }, [boxes]);

  const fetchApiKey = async (clientName) => {
    setLoading(true);
    try {
      console.log("Fetching API key for client:", clientName);
      const response = await fetch(`${API_URL}/api/getStallionApiKey`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clientName }),
      });
      const data = await response.json();
      console.log("Fetched API keys:", data);

      if (data.apiKey) {
        setApiKey(data.apiKey);
        setClientName(clientName);
        fetchCredits(data.apiKey);
        setError("");
      } else {
        setError("API key not found for client: " + clientName);
      }
    } catch (error) {
      console.error("Error fetching credits:", error);
      setError("Failed to fetch credits. Please try again.");
      alert("No Stallion Account Located, is this their first US shipment?");
    } finally {
      setLoading(false);
    }
  };

  const fetchCredits = async (apiKey) => {
    try {
      const creditsResponse = await fetch(
        "https://ship.stallionexpress.ca/api/v3/credits",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const creditsData = await creditsResponse.json();
      console.log("Fetched credits data:", creditsData);

      setCredits(parseFloat(creditsData.credits));
    } catch (error) {
      console.error("Error fetching credits:", error);
      setError("Failed to fetch credits. Please try again.");
    }
  };

  const calculateRequiredCredits = (boxes) => {
    console.log(boxes);
    let runningTotal = 0;
    boxes.forEach((box) => {
      const weightLbs = box.weight * 2.20462; // Convert kg to lbs
      let amount = 0; // Initialize the amount for each item

      if (weightLbs < 10) {
        amount = 3.99;
      } else if (weightLbs >= 10 && weightLbs < 20) {
        amount = 5.99;
      } else if (weightLbs >= 20 && weightLbs < 40) {
        amount = 9.99;
      } else {
        amount = 12.99;
      }

      runningTotal += amount;

      // Log the box number, weight, and amount required for it
      console.log(
        `Box ${box.boxNumber}: Weight = ${weightLbs.toFixed(
          2
        )} lbs, Amount = $${amount.toFixed(2)}`
      );
    });

    setRequiredCredits(parseFloat(runningTotal.toFixed(2)));
    console.log("Total Required Credits:", runningTotal.toFixed(2));
  };

  const getPrice = (asin) => {
    const data = asinData.find((item) => item.ASIN === asin);
    return data ? parseFloat(data.VALUE) : 0;
  };

  const calculateBoxValue = (box) => {
    return box.items.reduce((acc, item) => {
      return acc + getPrice(item.ASIN) * item.QTY;
    }, 0);
  };

  const handlePurchaseAmountChange = (event) => {
    setPurchaseAmount(event.target.value);
  };

  useEffect(() => {
    if (credits !== null && requiredCredits !== null) {
      const difference = Math.max(10, requiredCredits - credits);
      setPurchaseAmount(difference > 0 ? difference.toFixed(2) : "");
    }
  }, [credits, requiredCredits]);

  const handlePurchaseCredits = async () => {
    if (purchaseAmount < 10) {
      setError("Minimum purchase amount is $10");
      return;
    }

    setPurchaseLoading(true);
    try {
      const purchaseResponse = await fetch(
        "https://ship.stallionexpress.ca/api/v3/credits",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({ amount: parseFloat(purchaseAmount).toFixed(2) }),
        }
      );

      const purchaseData = await purchaseResponse.json();
      console.log("Purchased credits:", purchaseData);

      if (purchaseData.success) {
        fetchCredits(apiKey);
        setPurchaseAmount("");
      } else {
        setError("Failed to purchase credits. Please try again.");
      }
    } catch (error) {
      console.error("Error purchasing credits:", error);
      setError("Failed to purchase credits. Please try again.");
    } finally {
      setPurchaseLoading(false);
    }
  };

  const handlePurchaseLabels = async () => {
    console.log("Trying to purchase labels...");
    setPurchaseLoading(true);
    try {
      let labels = [];
      // Create a new PDFDocument instance with pdf-lib
      const pdfDoc = await PDFDocument.create();

      const totalBoxes = boxes.length;

      for (const [index, box] of boxes.entries()) {
        const boxValue = calculateBoxValue(box); // Calculate the value of the box
        let data;
        if (useMockData) {
          // Fetch the mock response from the server
          const response = await fetch(`${API_URL}/mockResponse`);
          data = await response.json();
        } else {
          const response = await fetch(
            "https://ship.stallionexpress.ca/api/v3/shipments",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
              body: JSON.stringify({
                name: clientName.substring(0, 40),
                address1: address,
                address2: selectedPlan.destination_address.address_line_2,
                city: city,
                province_code: state,
                postal_code: zip,
                country_code: selectedPlan.destination_address.country_code,
                weight_unit: "kg",
                weight: parseFloat(box.weight),
                length: parseFloat(box.length),
                width: parseFloat(box.width),
                height: parseFloat(box.height),
                size_unit: "cm",
                items: box.items.map((item) => ({
                  currency: "USD",
                  description: item.TITLE.slice(0, 40),
                  quantity: item.QTY,
                  sku: item.ASIN,
                  value: getPrice(item.ASIN), // Use the getPrice function to get the item value
                })),
                value: boxValue, // Use the calculated box value
                currency: "USD",
                package_type: "parcel",
                postage_type: "ups_standard",
                signature_confirmation: false,
                label_format: "png",
                purchase_label: true,
                is_fba: true,
              }),
            }
          );
          console.log(response);
          console.log(box);
          console.log("Sent the following data body to the API:", {
            name: clientName.substring(0, 40),
            address1: address,
            address2: selectedPlan.destination_address.address_line_2,
            city: city,
            province_code: state,
            postal_code: zip,
            country_code: selectedPlan.destination_address.country_code,
            weight_unit: "kg",
            weight: parseFloat(box.weight),
            length: parseFloat(box.length),
            width: parseFloat(box.width),
            height: parseFloat(box.height),
            size_unit: "cm",
            items: box.items.map((item) => ({
              currency: "USD",
              description: item.TITLE.slice(0, 40),
              quantity: item.QTY,
              sku: item.ASIN,
              value: getPrice(item.ASIN), // Use the getPrice function to get the item value
            })),
            value: boxValue, // Use the calculated box value
            currency: "USD",
            package_type: "parcel",
            postage_type: "ups_standard",
            signature_confirmation: false,
            label_format: "png",
            purchase_label: true,
            is_fba: true,
          });
          data = await response.json();
        }
        console.log("Is using mock data:", useMockData);
        console.log(data);
        if (data.success || useMockData) {
          labels.push({
            label: data.label_url, // Use the label URL instead of base64 data
            tracking_code: data.tracking_code,
            box_number: box.boxNumber,
          });
          console.log("Purchased label for box:", box.boxNumber);
          console.log("Label URL:", data.label_url);

          // Fetch PDF from the label URL
          const labelPdfResponse = await fetch(data.label_url);
          const labelPdfBytes = await labelPdfResponse.arrayBuffer();

          // Load the label PDF
          const labelPdfDoc = await PDFDocument.load(labelPdfBytes);

          // Add the label pages to the new PDF document
          const copiedPages = await pdfDoc.copyPages(
            labelPdfDoc,
            labelPdfDoc.getPageIndices()
          );
          copiedPages.forEach((page) => {
            pdfDoc.addPage(page);
          });

          // Add custom text to each page (like "B1 of 5")
          const pages = pdfDoc.getPages();
          const lastPage = pages[pages.length - 1];
          const { width, height } = lastPage.getSize();
          lastPage.drawText(`B${index + 1} of ${totalBoxes}`, {
            x: 2.1*72, // X-coordinate for text
            y: 1.3*72, // Y-coordinate for text
            size: 14,
            color: rgb(0, 0, 0),
          });
        } else {
          setError("Failed to purchase labels for one or more boxes.");
          console.error("Error purchasing label:", data.message);
        }
      }

      setLabels(labels);
      setPurchaseComplete(true);

      // Save labels to a file with the fba_shipment_id prefix
      const fba_shipment_id = selectedPlan.fba_shipment_id;
      const labelsString = JSON.stringify(labels, null, 2);

      // Save the PDF file with the fba_shipment_id prefix
      const pdfBytes = await pdfDoc.save();
      setStallionPdfBytes(pdfBytes); // Save Stallion PDF bytes for merging later
      saveAs(
        new Blob([pdfBytes], { type: "application/pdf" }),
        `USA Labels ${fba_shipment_id}_${selectedPlan.name}.pdf`
      );

      setPurchaseLoading(false);
    } catch (error) {
      console.error("Error purchasing labels:", error);
      setError("Failed to purchase labels. Please try again.");
      setPurchaseLoading(false);
    }
  };

  const handleDownloadMergedLabels = async () => {
    if (!stallionPdfBytes || !upsPdfUrl) {
      console.error("Stallion or UPS labels not ready");
      return;
    }

    try {
      const mergedPdf = await PDFDocument.create();

      // Load Stallion PDF
      const stallionPdf = await PDFDocument.load(stallionPdfBytes);
      const stallionPages = await mergedPdf.copyPages(
        stallionPdf,
        stallionPdf.getPageIndices()
      );

      // Fetch UPS PDF from the URL and load it
      const upsPdfResponse = await fetch(upsPdfUrl);
      const upsPdfBytes = await upsPdfResponse.arrayBuffer();
      const upsPdf = await PDFDocument.load(upsPdfBytes);
      const upsPages = await mergedPdf.copyPages(
        upsPdf,
        upsPdf.getPageIndices()
      );

      // Merge UPS and Stallion pages alternatively
      const totalPages = Math.max(upsPages.length, stallionPages.length);
      for (let i = 0; i < totalPages; i++) {
        if (upsPages[i]) mergedPdf.addPage(upsPages[i]);
        if (stallionPages[i]) mergedPdf.addPage(stallionPages[i]);
      }

      const mergedPdfBytes = await mergedPdf.save();
      saveAs(
        new Blob([mergedPdfBytes], { type: "application/pdf" }),
        `MERGED_Labels_${selectedPlan.fba_shipment_id}_${selectedPlan.name}.pdf`
      );
    } catch (error) {
      console.error("Error merging labels:", error);
    }
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Purchase Labels</Typography>
          <Paper style={{ padding: "16px", marginBottom: "8px" }}>
            {loading ? (
              <CircularProgress />
            ) : credits !== null ? (
              <>
                <Typography variant="h6">
                  API Key Found for client: {clientName}
                </Typography>
                <Typography variant="body1">
                  Available Credits: ${credits}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: credits >= requiredCredits ? "green" : "red",
                  }}
                >
                  Required Credits: ${requiredCredits}
                </Typography>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <TextField
                    label="Purchase Credits"
                    value={purchaseAmount}
                    onChange={handlePurchaseAmountChange}
                    required
                    helperText="Minimum $10"
                    style={{ width: "180px", marginRight: "16px" }}
                    inputProps={{ maxLength: 6 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchaseCredits}
                    disabled={purchaseLoading || purchaseAmount < 10}
                  >
                    {purchaseLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Purchase Credits"
                    )}
                  </Button>
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchaseLabels}
                    disabled={credits < requiredCredits || purchaseLoading}
                  >
                    {purchaseLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Purchase Labels"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownloadMergedLabels}
                    disabled={!purchaseComplete || !upsPdfUrl}
                  >
                    {purchaseLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Download Stallion + UPS Labels"
                    )}
                  </Button>
                </Box>
              </>
            ) : (
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PurchaseLabels;
